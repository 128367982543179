import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import {
  getContentChild,
  addNewChildContent,
  orderJourneyPart,
} from "../../services/NexiDigitalAPI";
import { Button } from "react-bootstrap";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import { FaPlus } from "react-icons/fa";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import { AuthContext } from "../../context/AuthContext";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

/* Lista Capitulos de Jornadinha */

const ListaCapitulo = ({parent, saveData}) => {
  const [listaDeCapitulos, setListaDeCapitulos] = useState([""]);
  const { loginData } = useContext(AuthContext);
  const secondaryColor = loginData.secondaryColor;
  const parentId = parent.data.idContentItem;
  const [enableEdit, setEnableEdit] = useState(false);

  // get lista de capítulos using props.parent (idContentItem do pai)
  const getListaDeCapitulos = async (idContentItem) => {
    await getContentChild(idContentItem)
      .then((res) => {
        if (res.data.listJourneyPart && res.data.listJourneyPart.length > 0) {
          setEnableEdit(true);
          setListaDeCapitulos(res.data.listJourneyPart);
        } else {
          setListaDeCapitulos(() => {
            return [
              {
                text: `Não existem capítulos para essa jornadinha, clique em "Adicionar" para criar um capítulo.`,
              },
            ];
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getListaDeCapitulos(parentId); // get lista de capitulo assim que carrega o componente 1a vez
  }, []);

  const addCapituloHandler = () => {
    saveData({saveOnly: true})
    addNewChildContent({
      parent: parentId,
      idContentType: parent.data.idContentType + 1, // if parent 15 chapter is 16, if impact parent is 22 chapter is 23
      idContentGroup: parent.data.idContentGroup,
    }).then((res) => {
      if (res.data.success) {
        // Se conseguiu criar o conteúdo, redireciona para o editor deste conteúdo
        parent.history.push(`/cms/content/${res.data.idContentItem}`);
      }
    }).catch(e => {
      console.log('error', e)
    });
  };

  const buttonUpClickHandler = (index) => {
    if (index > 0) {
      let newOrder = [];
      listaDeCapitulos.map((item) => {
        newOrder.push(item.idContentItem);
      });
      newOrder.splice(index - 1, 0, newOrder.splice(index, 1)[0]);
      orderJourneyPart(newOrder).then((res) => {
        if (res.data.success) {
          getListaDeCapitulos(parentId);
        }
      });
    }
  };

  const buttonDownClickHandler = (index) => {
    if (index + 1 < listaDeCapitulos.length) {
      let newOrder = [];
      listaDeCapitulos.map((item) => {
        newOrder.push(item.idContentItem);
      });
      newOrder.splice(index + 1, 0, newOrder.splice(index, 1)[0]);
      orderJourneyPart(newOrder).then((res) => {
        if (res.data.success) {
          getListaDeCapitulos(parentId);
        }
      });
    }
  };

  return (
    <section>
      <div
        style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
      >
        <Button
          onClick={() => addCapituloHandler()}
          style={{ display: "flex", alignItems: "center", marginBottom: 25 }}
        >
          <FaPlus style={{ marginRight: 10 }} />
          Adicionar
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <Thead style={{ backgroundColor: secondaryColor }}>
            <Tr>
              <Th align="center" style={{ color: "#FFF" }}>
                #
              </Th>
              <Th align="center" style={{ color: "#FFF" }}>
                Título
              </Th>
              {enableEdit && (
                <Th align="center" style={{ color: "#FFF" }}>
                  Ação
                </Th>
              )}
            </Tr>
          </Thead>
          <Tbody>
            {listaDeCapitulos.map((item, index) => {
              return (
                <Tr key={index}>
                  <Td align="center" style={{ minWidth: "35%" }}>
                    {enableEdit ? index + 1 : ""}
                  </Td>
                  <Td>{item.text != null ? `${item.text}` : ""}</Td>
                  {enableEdit && (
                    <Td align="center" className="journey-item-btn-row">
                      <NavLink
                        style={{ backgroundColor: secondaryColor }}
                        key={index}
                        to={`/cms/content/${item.idContentItem}`}
                        className={`btn btn-primary journey-item-btn`}
                      >
                        Editar
                      </NavLink>
                      <button
                        className="btn btn-primary journey-item-direction-btn journey-item-btn"
                        style={{ backgroundColor: secondaryColor }}
                        onClick={() => {
                          buttonUpClickHandler(index);
                        }}
                      >
                        <FiArrowUp size="15" />
                      </button>
                      <button
                        className="btn btn-primary journey-item-direction-btn journey-item-btn"
                        style={{ backgroundColor: secondaryColor }}
                        onClick={() => {
                          buttonDownClickHandler(index);
                        }}
                      >
                        <FiArrowDown size="15" />
                      </button>
                    </Td>
                  )}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </section>
  );
};

export default ListaCapitulo;
