import React, { useState, useEffect } from "react";
import Filtro from './Filtro';
import Table from './Table';
import ReportAcordeon from '../../../Components/ReportAcordeon'
import { 
    getReportSections
} from "../../../../../services/NexiDigitalAPI";


const Content = ({ index, expanded }) => {
    const name = "Usuários com mais acessos"
    const description = "Detalhes sobre as visitas.";

    const [loading, setLoading] = useState(false);
    const [dados, setDados] = useState([]);

    const actionFilter = async (values) => {

        setLoading(true);

        // Sessions
        const reportSections = await getReportSections(values)
        setDados(reportSections);
   
        
        setLoading(false);
    }

    const filter = (
        <Filtro 
            actionFilter={actionFilter} 
            loading={loading} 
        />
    );

    const result = (
        <Table 
            dados={ dados } 
            loading={ loading }
        />
    );
    
    return (
        <ReportAcordeon
            name={name}
            description={description}
            filter={filter}
            result={result}
            index={ index }
            expanded={expanded}
        />
        
    )
}

export default Content;