import React, { useContext } from 'react';
import styles from './styles.module.scss';
import { AuthContext } from "../../../../../context/AuthContext";
import { ReactComponent as IcoUp } from '../../../../../assets/images/ico-up.svg';
import { ReactComponent as IcoDown } from '../../../../../assets/images/ico-down.svg';

const ResumeBlock = ({ label, data, percentage}) => {

    const { loginData } = useContext(AuthContext)
    const fourthColor = loginData.fourthColor

    const stylePointer = (percentage >= 0) ? 
    { fill: '#31BA7E', color: '#31BA7E', } : 
    { fill: '#BA3131', color: '#BA3131', }
    
    const pointer = (percentage >= 0) ? (<IcoUp style={stylePointer} />) : (<IcoDown style={stylePointer} />);
    
    return (
        <div className={styles.report_item} style={{ color: fourthColor }}>
            <span className={styles.report_item_text}>
                { label }
            </span>
            <span className={styles.report_item_data}>
                { data }
            </span>
            {<span className={styles.history} style={stylePointer} >
                { pointer } { percentage }%
            </span>}
        </div>
    );
}

export default ResumeBlock;