// Basic
import React, { useContext, useState, useEffect } from "react";

// UI and icons
import "./../Cockpit.css";
import { getMenuRoutes } from "../../utils/Utils";
import { AuthContext } from "../../context/AuthContext";
import { getTokenToApplyQrcode } from "../../services/NexiDigitalAPI";
import Button from "@material-ui/core/Button";
import { Col } from "react-bootstrap";
import Loading from '../../components/Loading'

export default function Cockpit() {
  const { loginData } = useContext(AuthContext);
  const primaryColor = loginData?.primaryColor;
  const [loading, setLoading] = useState(true);
  const [qrcode, setQrcode] = useState("");
  const [qrcodeInfos, setQrcodeInfos] = useState("");

  const QRCode = require("qrcode.react");

  async function getQrcode() {
    try{
      const token = await getTokenToApplyQrcode();
      setQrcode(token.url);
      setQrcodeInfos(token.instructions);
    } catch(e){

    } finally{
      setLoading(false)
    }
  }

  /* [Permission Module] - Permissions states */
  const [yourStateConstDeclared, setYourStateConstDeclared] = useState(false);

  /* [Permission Module] - Menu Options */
  async function getMenuOption() {
    const menuOptionList = getMenuRoutes();
    const objCurrentMenu = menuOptionList.filter(
      (menu) => menu.text === "Cadastro de Usuários" // Use the name of the menu-option that is coming to your module
    );

    if (Boolean(objCurrentMenu.length)) {
      const resources = objCurrentMenu.map((menu, index) => menu.resource);

      getPermissionsPage(resources);
    }
  }

  /* [Permission Module] - Permission attrs in page by login data information */
  function getPermissionsPage(resources) {
    try {
      if (
        Boolean(
          resources[0].filter(
            (menu) => menu.name === "Name of the resource is coming"
          ).length
        )
      )
        setYourStateConstDeclared(true);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getMenuOption();
    getQrcode();
  }, []);

  return (
    <div className="Cockpit">
      <div className="header-title-wrapper">
        <div className="header-title">
          <span>QRCode de Autoinscrição</span>
        </div>
      </div>
      {(loading) && (
        <Loading />
      )}
      {(qrcode && !loading) && (
        <>
          <hr />
          <div className="form-row">
            <div className="input-group mb-3">
              <Col xs={12} sm={12} align="center">
                <Button
                  className="input-group-text"
                  style={{ backgroundColor: primaryColor, color: "white" }}
                  onClick={() => {
                    navigator.clipboard.writeText(qrcode);
                  }}
                >
                  Copiar Link de Redirecionamento
                </Button>
                <br /><br />
                <QRCode value={qrcode} size={220} />
                <br />
                
              </Col>
              <Col xs={2} sm={3} align="center"></Col>
              <Col xs={8} sm={6} align="center">
                <div dangerouslySetInnerHTML={{ __html: qrcodeInfos }} />
              </Col>
              <Col xs={2} sm={3} align="center"></Col>
            </div>
          </div>
        </>
      )}
      {(!qrcode && !loading) && (
        <>
          <hr />
          <div className="form-row">
            <div className="input-group mb-3">
              <Col xs={12} sm={12} align="center">
                Ocorreu um erro e não foi possível gerar link de Autoinscrição
              </Col>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
