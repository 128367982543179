import React, { useState, useEffect } from "react";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./styles.scss";

import CadastroOferta from "./CadastroOferta";
import CadastroProduto from "./CadastroProduto";
import EstornoVendas from "./EstornoVendas";
import RelatorioVendas from "./RelatorioVendas";
import { getMenuRoutes } from "../../utils/Utils"; // The location path will depend of your current module

function Loja() {
  /* [Permission Module] - Permissions states */
  const [permissionSalesReport, setPermissionSalesReport] = useState(false);
  const [permissionSalesReversal, setPermissionSalesReversal] = useState(false);
  const [permissionOfferRegistration, setPermissionOfferRegistration] =
    useState(false);
  const [permissionProductRegistration, setPermissionProductRegistration] =
    useState(false);

  /* [Permission Module] - Menu Options */
  async function getMenuOption() {
    const menuOptionList = getMenuRoutes();
    
    const objCurrentMenu = menuOptionList.filter(
      (menu) => menu.text === "Gestão da Loja" // Use the name of the menu-option that is coming to your module
    );

    if (Boolean(objCurrentMenu.length)) {
      const resources = objCurrentMenu.map((menu, index) => menu.resource);

      getPermissionsPage(resources);
    }
  }

  /* [Permission Module] - Permission attrs in page by login data information */
  function getPermissionsPage(resources) {
    try {
      if (
        Boolean(
          resources[0].filter((menu) => menu.name === "Relatorio de Vendas")
            .length
        )
      )
        setPermissionSalesReport(true);
      if (
        Boolean(
          resources[0].filter((menu) => menu.name === "Estorno de Vendas")
            .length
        )
      )
        setPermissionSalesReversal(true);
      if (
        Boolean(
          resources[0].filter((menu) => menu.name === "Cadastro de Ofertas")
            .length
        )
      )
        setPermissionOfferRegistration(true);
      if (
        Boolean(
          resources[0].filter((menu) => menu.name === "Cadastro de Produtos")
            .length
        )
      )
        setPermissionProductRegistration(true);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    /* [Permission Module] - Function calls */
    getMenuOption();
  }, []);

  return (
    <div className="Reports">
      <div className="header-title-wrapper">
        <div className="header-title">Loja</div>
      </div>

      <Tabs forceRenderTabPanel>
        <TabList>
          {permissionSalesReport && <Tab>Relatório de vendas</Tab>}
          {permissionSalesReversal && <Tab>Estorno de vendas</Tab>}
          {permissionOfferRegistration && <Tab>Cadastro de ofertas</Tab>}
          {permissionProductRegistration && <Tab>Cadastro de Produtos</Tab>}
        </TabList>

        {permissionSalesReport && (
          <TabPanel>
            <RelatorioVendas />
          </TabPanel>
        )}

        {permissionSalesReversal && (
          <TabPanel>
            <EstornoVendas />
          </TabPanel>
        )}

        {permissionOfferRegistration && (
          <TabPanel>
            <CadastroOferta />
          </TabPanel>
        )}

        {permissionProductRegistration && (
          <TabPanel>
            <CadastroProduto />
          </TabPanel>
        )}
      </Tabs>
    </div>
  );
}

export default Loja;
