import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter, Redirect } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import _, { iteratee } from "underscore";
import ReactTags from "react-tag-autocomplete";
import moment from "moment";
import {
  updateContent,
  getTags,
  uploadImageApi,
  zoom_edit,
  zoom_add,
} from "../../services/NexiDigitalAPI";
import SaveIcon from "@material-ui/icons/Save";
import SemImagem from "../../assets/images/sem-imagem.jpg";
import AccessTable from "../AccessTable";
import { AuthContext } from "../../context/AuthContext";
import * as QueryString from "query-string"
import Tags from "../../components/Tags";
import { Form } from "react-bootstrap";

const Meeting = (props) => {
  // remove o Z no final da data retornado da API (bugfix)
  const removeUtcTimezone = (time) => {
    if(time && time[time.length - 1] == 'Z'){
      return time.slice(0, time.length - 1)
    }
    return time
  }
  const { data, contentTags, permissionApproveContent } = props;
  
  const queryParams = QueryString.parse(props.location.search);
  const { noZoomAuth } = queryParams

  // Lista de variáveis em ordem alfabética (para bater com a lista recebida do json)
  const [active, setActive] = useState(data.active ||  0);
  const [autor, setAutor] = useState(data.author || "");
  const [autorEmail, setAutorEmail] = useState(data.authorEmail || "");
  const [backConfirmation, setBackConfirmation] = useState(
    data.backConfirmation || ""
  );
  const [backgroundColor, setBackgroundColor] = useState(
    data.backgroundColor || ""
  );
  const [content, setContent] = useState(data.content || "");
  const [contentLocation, setContentLocation] = useState(
    data.contentLocation || ""
  );
  const [description, setDescription] = useState(data.description || "");
  const [endDate, setEndDate] = useState(
    data.endDate === null ? new Date() : new Date(removeUtcTimezone(data.endDate))
  );
  const [idContentGroup, setIdContentGroup] = useState(data.idContentGroup);
  const [idContentItem, setIdContentItem] = useState(data.idContentItem);
  const [idContentType, setIdContentType] = useState(data.idContentType);
  const [idSection, setIdSection] = useState(data.idSection);
  const [imagemS3, setImagemS3] = useState(data.image || "");
  const [imageStyle, setImageStyle] = useState(data.imageStyle || "");
  const [initDate, setInitDate] = useState(
    data.initDate === null ? new Date() : new Date(removeUtcTimezone(data.initDate))
  );
  const [logoutConfirmation, setLogoutConfirmation] = useState(
    data.logoutConfirmation
  );
  const [referenceLink, setReferenceLink] = useState(data.referenceLink || "");
  const [showBottomMenu, setShowBottomMenu] = useState(data.showBottomMenu);
  const [showTopBar, setShowTopBar] = useState(data.showTopBar);
  const [titulo, setTitulo] = useState(data.text || "");
  const [tag, setTag] = useState("");
  const [imagemUploadStatus, setImagemUploadStatus] = useState(false);
  const [mensagemImagemUpload, setMensagemImagemUpload] = useState("");
  const [duration, setDuration] = useState(0);
  const [minute, setMinute] = useState(0);
  const [hour, setHour] = useState(0);

  const { loginData } = useContext(AuthContext)
  const fourthColor = loginData?.fourthColor
  const secondaryColor = loginData?.secondaryColor

  // variáveis de auxílio
  let tagsDeConteudo = [];
  for (let i = 0; i < contentTags.length; i++) {
    tagsDeConteudo.push({ id: i, name: contentTags[i].tag });
  }

  const [imagemOriginal, setImagemOriginal] = useState(data.image || "");
  const [carregaImagem, setCarregaImagem] = useState(false);
  const [tags, setTags] = useState(tagsDeConteudo);
  const [lista, setLista] = useState([]);

  const diferenca = moment(endDate, "DD/MM/YYYY HH:mm:ss").diff(
    moment(initDate, "DD/MM/YYYY HH:mm:ss")
  );
  const duracao_calculada = moment.duration(diferenca);
  const duracao = duracao_calculada.asHours() * 60;

  useEffect(() => {
    getTags()
      .then((res) => {
        const tagSearch = res.data.tagsearch;
        const tagsSorted = _.sortBy(tagSearch, "tag");
        let nova = [];

        for (let i = 0; i < tagsSorted.length; i++) {
          nova.push({ id: tagsSorted[i].idTag, name: tagsSorted[i].tag });
        }

        setLista(nova);
      })
      .catch((err) => {
        console.log(err);
      });
    convertDifference();
  }, []);

  const uploadImagem = (arquivo) => {
    setCarregaImagem(true);
    let selectedImageFile = arquivo.files[0];
    const fileImageName = "thumb" + idContentItem + "-" + Date.now();
    const dataImage = new FormData(); // If file selected
    if (selectedImageFile) {
      dataImage.append("fileName", fileImageName);
      dataImage.append("fileData", selectedImageFile);
      // dataImage.append("profileImage", newImageFile, newImageFile.name);

      uploadImageApi(dataImage)
        .then((zoom) => {
          if (zoom.data.error) {
            setImagemS3(imagemOriginal);
            setCarregaImagem(false);
            setImagemUploadStatus(false);
            setMensagemImagemUpload("Erro ao fazer upload do arquivo");
          } else {
            setImagemS3(zoom.data.location);
            setCarregaImagem(false);
            setImagemUploadStatus(true);
            setMensagemImagemUpload("Imagem atualizada com successo");
          }
        })
        .catch((error) => {
          // If another error
          console.log("error", error);
          setImagemS3(imagemOriginal);
          setCarregaImagem(false);
          setImagemUploadStatus(false);
          setMensagemImagemUpload("Erro ao fazer upload do arquivo");
        });
    } else {
      setImagemS3(imagemOriginal);
      setCarregaImagem(false);
    }
  };

  const formatAppId = (id) => {
    const digitos = 7;
    let len = id.length;

    let zeros = "0".repeat(digitos - len);

    return `app${zeros}${id}`;
  };

  const removePrefixoURL = (url) => {
    const app_id = loginData.app_id;
    const app_code = formatAppId(app_id);

    if (url.includes(app_code) === false) {
      return url;
    }

    let uri = url.split(app_code)[1];
    //remove a barra que sobrou no começo
    while (uri.substring(0, 1) === "/") {
      uri = uri.substring(1);
    }
    return uri;
  };

  const _zoomAdd = (meetingParams, contentParams) => {
      zoom_add(meetingParams)
      .then((response) => {
        // depois salva no banco de dados.
        const zoom = response.data;

        if (zoom.name === "Error") {
          console.log("Erro ao criar reunião no Zoom", zoom.message);
          return false;
        }
        
        contentParams = {
          ...contentParams,
          referenceLink: zoom.join_url,
          contentLocation: zoom.join_url,
        }

        updateContent(contentParams)
          .then((res) => {
            if (res.data.success) {
              props.history.goBack();
            }
          })
          .catch((error) => {
            // If another error
            console.log("error", error);
          });
      })
      .catch((e) => console.log("Erro ao criar reunião no zoom", e));
  }

  const sendData = () => {
    const userId = loginData.userid; // Usuário logado
    const tagList = _.map(tags, function (t) {
      return t.name;
    }).join(";");
    
    const meetingParams = {
      //id: referenceLink,
      topic: titulo,
      type: 2, //Scheduled meeting
      start_time: moment(initDate).format("YYYY-MM-DD hh:mm:ss.SSS"),
      duration: convertTimeToMinutes(),
      agenda: description,
      timezone: "America/Sao_Paulo",
    };

    const contentParams = {
      contentLocation,
      idContentItem: idContentItem,
      idContentGroup: idContentGroup,
      image: imagemS3,
      text: titulo,
      description: description,
      publishDate:  moment(initDate).format("YYYY-MM-DD hh:mm:ss.SSS"),
      initDate: moment(initDate).format("YYYY-MM-DD hh:mm:ss.SSS"),
      endDate: getNewDate(),
      content: content,
      idUserPublisher: parseInt(userId, 10),
      author: autor,
      authorEmail: autorEmail,
      active: parseInt(active),
      idContentType: idContentType,
      tags: tagList,
      backConfirmation: backConfirmation === 1 ? 1 : 0,
      backgroundColor: backgroundColor,
      idSection: idSection,
      imageStyle: imageStyle,
      logoutConfirmation: logoutConfirmation,
      showBottomMenu: showBottomMenu,
      showTopBar: showTopBar,
    };

    if(noZoomAuth){
      updateContent(contentParams)
          .then((res) => {
            if (res.data.success) {
              props.history.goBack();
            }
          })
          .catch((error) => {
            console.log("error", error);
          });
    }else{
      _zoomAdd(meetingParams, contentParams)
    }

  };

  const onDelete = (i) => {
    tags.splice(i, 1);
  };

  const onAddition = (e) => {
    setTags([...tags, { id: e.id, name: e.name }]);
  };

  const salvaNoZoom = async (meeting) => {};

  function convertDifference() {
    let totalMinutes = duracao;

    let hours = Math.floor(totalMinutes / 60);
    let minutes = Math.round(totalMinutes % 60);

    setHour(hours);
    setMinute(minutes);
  }

  function convertTimeToMinutes() {
    let value = parseInt(hour) * 60 + parseInt(minute);
    return value;
  }

  function getNewDate() {
    let newDate = moment(initDate, "hh:mm:ss A")
      .add(convertTimeToMinutes(), "minutes")
      .format("YYYY-MM-DD hh:mm:ss.SSS");

    return newDate;
  }

  return (
    <div>
      <div className="header-title-wrapper">
        <div className="header-title">Meeting</div>
      </div>
      <div className="Meeting-form">
        <form>
          <div className="form-group">
            <div className="card">
              <div  style={{ backgroundColor: fourthColor || secondaryColor }} className="card-header template-card-header">Título</div>
              <div className="card-body template-card-body">
                <div className="input-group mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      Título
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="titulo"
                    aria-describedby="titulo"
                    value={titulo}
                    onChange={(e) => setTitulo(e.target.value)}
                  />
                </div>
                <div className="input-group mb-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="basic-addon1">
                      Descrição
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    id="description"
                    aria-describedby="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                {
                  noZoomAuth && (
                    <div className="input-group mb-4">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">
                          Link para o Conteúdo
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        id="contentLocation"
                        aria-describedby="contentLocation"
                        value={contentLocation}
                        onChange={(e) => setContentLocation(e.target.value)}
                      />
                    </div>
                  )
                }
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="card">
              <div  style={{ backgroundColor: fourthColor || secondaryColor }} className="card-header template-card-header">Tags</div>
              <div className="card-body template-card-body">
                <div className="row">
                  <div className="col-md-12">
                    <Tags
                      handleNewTags={(newTags) => setTags(newTags)}
                      lista={lista}
                      contentTags={contentTags}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="card">
              <div  style={{ backgroundColor: fourthColor || secondaryColor }} className="card-header template-card-header">Imagem</div>
              <div className="card-body template-card-body">
                <div className="row">
                  <div className="col-md-6">
                    <input
                      type="file"
                      className="form-control-file"
                      id="imagem"
                      accept="image/png, image/jpeg, image/gif"
                      onChange={(e) => uploadImagem(e.target)}
                    />
                  </div>
                  {carregaImagem ? (
                    <div className="col-md-6">
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className="col-md-6">
                      <img
                        src={imagemS3 || SemImagem}
                        alt="Imagem"
                        className="img-fluid img-thumbnail image-effect"
                        style={{ height: 130 }}
                      />
                      <br />
                      <br />
                      {imagemUploadStatus ? (
                        <span style={{ color: "green" }}>
                          {mensagemImagemUpload}
                        </span>
                      ) : (
                        <span style={{ color: "red" }}>
                          {mensagemImagemUpload}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="card">
              <div  style={{ backgroundColor: fourthColor || secondaryColor }} className="card-header template-card-header">
                Autor
              </div>
              <div className="card-body template-card-body">
                <div className="form-row">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="autor">
                        Nome
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      value={autor}
                      onChange={(e) => setAutor(e.target.value)}
                    />
                  </div>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="autor">
                        E-mail
                      </span>
                    </div>
                    <input
                      type="email"
                      className="form-control"
                      value={autorEmail}
                      onChange={(e) => setAutorEmail(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div className="form-group">
            <div className="card overflow-visible">
              <div  style={{ backgroundColor: fourthColor || secondaryColor }} className="card-header template-card-header">Publicação</div>
              <div className="card-body template-card-body">
                  <div className="form-row">
                  {permissionApproveContent && (
                    <div className="col-md-4">
                      <Form.Group className="access-table-select">
                          <Form.Label>Status</Form.Label>
                          <Form.Control as="select"
                            value={ active }
                            onChange={(e) => setActive(e.target.value)}
                          >
                            <option value={0}>Avaliação pendente</option>
                            <option value={1}>Aprovado</option>
                            <option value={2}>Cancelado</option>                       
                          </Form.Control>
                      </Form.Group>
                    </div>
                  )} 
                  <div className="col-md-4" style={{ textAlign: "center" }}>     
                      <div style={{ display: "inline-block", textAlign: "left" }}>               
                        <Form.Group className="access-table-select">
                          <Form.Label>Inicio da reunião</Form.Label><br/> 
                          <DatePicker
                            className="inputDateTime"
                            selected={initDate}
                            showTimeSelect
                            timeFormat="HH:mm"
                            dateFormat="dd/MM/yyyy HH:mm"
                            onChange={(date) => setInitDate(date)}
                            locale="pt-BR"
                          />
                        </Form.Group>
                    </div>
                  </div>
                  <div className="col-md-4">

                    <Form.Group className="access-table-select">
                      
                        <label htmlFor="init-date">Duração da reunião</label>
                          <br/>
                        
                        <select
                          style={{display:"inline-block", width: "25%"}}
                          className="form-control"
                          name="hours"
                          id="hours"
                          onChange={(e) => setHour(e.target.value)}
                        >
                          <option disabled selected value={hour}>
                            {hour}
                          </option>
                          {[...Array(10)].map((i, index) => {
                            return <option value={index}>{index}</option>;
                          })}
                        </select> <span style={{ display:"inline-block", marginRight: 15 }}>hr</span>
                        
                        
                        <select
                          style={{display:"inline-block", width: "25%"}}
                          className="form-control"
                          placeholder={33}
                          name="minutes"
                          id="minutes"
                          onChange={(e) => setMinute(e.target.value)}
                        >
                          <option disabled selected value={minute}>
                            {minute}
                          </option>
                          <option value={0}>0</option>
                          <option value={15}>15</option>
                          <option value={30}>30</option>
                          <option value={45}>45</option>
                        </select> <span style={{ display:"inline-block", marginRight: 15 }}>min</span>
                    </Form.Group>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>

          
          <div className="card">
            <div  style={{ backgroundColor: fourthColor || secondaryColor }} className="card-header template-card-header">
              Permissões de acesso ao conteúdo
            </div>
            <div className="card-body template-card-body">
              <AccessTable id={data.idContentItem} />
            </div>
          </div>
          <br />
          <div className="row" style={{ padding: 5 }}>
            <div className="col-md-8"></div>
            <div className="col-md-4">
              <button
                type="button"
                onClick={sendData}
                className="btn btn-primary"
              >
                <SaveIcon /> Salvar
              </button>
            </div>
          </div>
        </form>
        <br />
      </div>
    </div>
  );
};

export default withRouter(Meeting);
