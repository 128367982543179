import React, { useState, useEffect, useContext } from "react";
import "react-datepicker/dist/react-datepicker.css";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const Tags = ({ lista, contentTags, handleNewTags, disabled = false }) => {
    const [tags, setTags] = useState([]);

    const handleChange = (tagsArray) => {
        if(tags.length >= tagsArray.length || !tags.find(elem=> elem.id === tagsArray[tagsArray.length - 1].id)){
            const newTags = tagsArray.map(item => ({ id: item.id, name: item.name }))
            const newComponentTags = tagsArray.map(item => ({ id: item.id, title: item.name, name: item.name }))
            setTags(newComponentTags)
            handleNewTags(newTags)
        }
       
    }

    const getCurrentTags = () => {
        let currentTags = []
        for (let i = 0; i < contentTags.length; i++) {
            let tags = contentTags[i].tag ||  contentTags[i].name
            let id  = contentTags[i].id || i
            currentTags.push({ id: id, title: tags, name: tags  });
        }
        setTags(currentTags)
    }

    useEffect(() => {
        if(contentTags.length > 0){
            getCurrentTags()
        }
    }, [ contentTags ])

    return (
        <Autocomplete
            id="combo-box-demo"
            disabled={disabled}
            options={lista.map(item => ({id: item.id, title: item.name, name: item.name}))}
            value={tags}
            getOptionLabel={(option) => option.title}
            renderInput={(params) => <TextField {...params} label="Opções" variant="outlined" />}
            multiple
            onChange={(e, newValue) => handleChange(newValue)}
        />
    )
};

export default Tags;
