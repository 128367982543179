import React, { useContext, useEffect, useState } from "react";
import UserIcon from '../assets/images/user.svg'
import "./Header.css";
import { AuthContext } from "../context/AuthContext";
import Popover from '@material-ui/core/Popover';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Redirect } from 'react-router-dom'
import { colors } from "../utils/Colors";

export default function Header() {
  const [anchorEl, setAnchorEl] = useState(null)
  const [redirectLogin, setRedirectLogin] = useState(false)
  const authContext = useContext(AuthContext)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  function logout() {
    authContext.signOut()
    setRedirectLogin(true);
  }

  function getUserName(){
    return authContext?.loginData?.menuData?.name
  }

  if (redirectLogin) {
    return <Redirect to='/login' />;
  }

  return (
    <div className="Header" style={{ backgroundColor: colors.blueSmooth }}>
      <div className="header-container" onClick={(e) => { setAnchorEl(e.currentTarget) }}>
        <span style={{ marginRight: 15, fontSize: 14 }}>{ getUserName() }</span>
        <div 
          className="menu-header-icon"
        >
          <img
              style={{ color: 'white', width: 50 }}
              src={UserIcon}
          />
      </div>
      </div>
      <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div className="meeting-popover-container">
            <div className="meeting-popover-item" onClick={() => logout()} >
              <ExitToAppIcon style={{ height: 25, width: 25, marginRight: 10 }} />
              Sair
            </div>
          </div>
        </Popover>
    </div>
  );
}
