import React, { useState, useCallback, useEffect } from 'react';
import ChartsDays from '../../../Components/Charts/ChartsDays';
import moment from "moment";
import "moment/locale/pt-br.js";
import { GetUtilizationPerWeekDays } from "../../../../../services/NexiDigitalAPI";

const DayAccess = ( { hideData, firstElem, lastElem, nullfyDate, idGroupGlobal } ) => {

    const [loading, setLoading] = useState(false);

    const [data, setData] = useState([0, 0, 0, 0, 0, 0, 0]);

    const memorizedCallback = useCallback(async () => {
        if (!firstElem.date || !lastElem.date) {
            setData([0, 0, 0, 0, 0, 0, 0]);
            setLoading(false);
            return false;
        }
        setLoading(true);
           
        const filterParams = {
            startDate: moment(firstElem.date).format("YYYY-MM-DD"),
            endDate: moment(lastElem.date).format("YYYY-MM-DD"),
        };
        if( idGroupGlobal ){  
            filterParams.idGroup = parseInt(idGroupGlobal);
        }

        let responseData = await GetUtilizationPerWeekDays(filterParams);
        if (responseData) {
            let _values = [0, 0, 0, 0, 0, 0, 0];
            let Period = responseData;
            let index = 0;
            for (let elem in Period) {
                index = elem;
                if (elem === "0") {
                    index = 7;
                }
                _values[index - 1] = Period[elem].totalTime;
            }
            _values = Period;
            setData(_values);
            setLoading(false);
        } else {
            nullfyDate()
        }
    }, [firstElem, lastElem]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            memorizedCallback();
        }
        return () => (isMounted = false);
    }, [memorizedCallback]);

    

    return (
        <>
            {loading ? 
                (
                    <div className="chart-wrapper">
                        <div className="loader loader-big" />
                        <span>Aplicando filtro...</span>
                    </div>
                ) : (
                    <ChartsDays
                        data={data}
                        Period={
                            firstElem.day && lastElem.day
                            ? `Período: ${firstElem.day} ${firstElem.date}  à  ${lastElem.day} ${lastElem.date} `
                            : `Período de busca não definido`
                        }
                    />
                )
            }
        </>
    )
}

export default DayAccess;