import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import "./AddUser.css";
import UserForm from "./UserForm";

const api_url = process.env.REACT_APP_BACKEND_API;

export default function AddUser() {
  const history = useHistory();
  const token = localStorage.getItem("token");

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const submitResult = () => {
    history.goBack();
  }

  return (
    <UserForm submitResult={submitResult} token={token} notAuto={true}/>
  );
}
