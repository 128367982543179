// Basic
import React, { useContext, useState, useEffect } from "react";

// UI and icons
import "./../Cockpit.css";
import { AuthContext } from "../../context/AuthContext";
import {
  getProfiles,
  getPermissionResources,
  getProfileResourcesApi,
  addProfileResourcesApi,
  removeProfileResourcesApi,
  updateProfileApi,
  deleteProfile,
  getUsers,
  addUserIntoProfile,
  getProfileUsers,
  deleteUserOfProfile
} from "../../services/NexiDigitalAPI";
import Acordeon from "./Components/Acordeon";
import Switch from '@material-ui/core/Switch';
import {Redirect, useParams} from "react-router-dom";
import ToggleSwitch from "./Components/ToggleSwitch";
import { Person, HighlightOff, DeleteForever } from "@material-ui/icons";
import { FiUserPlus } from 'react-icons/fi';
import Button from '@material-ui/core/Button';
import {Modal, Col} from "react-bootstrap";
import FormField from "./Components/FormField";
import {makeStyles} from "@material-ui/core/styles";

export default function Cockpit( ) {
    const { loginData } = useContext(AuthContext);
    const [redirect, setRedirect] = useState(false);
    const primaryColor = loginData?.primaryColor;
    const [menuOption, setMenuOption] = useState([]);
    const [resources, setResources] = useState([]);
    const [profileResources, setProfileResources] = useState([]);
    const { idProfile } = useParams();
    const [profileNameTitle, setProfileNameTitle] = useState("");
    const [profileName, setProfileName] = useState("");
    const [showAlertDelete, setShowAlertDelete] = useState(false);
    const [userSelected, setUserSelected] = useState('');
    const [userList, setUserList] = useState([]);
    const [userListName, setUserListName] = useState('');
    const [values, setValues] = useState({
      idUser: 0
    });
    const [profileUsers, setProfileUsers] = useState([]);
    const [idUser, setIdUser] = useState('');

    async function getProfileName(){
      const profiles = await getProfiles();

      profiles.forEach(function(profile) {
        if (idProfile == profile.idProfile) {
          setProfileName(profile.name);
          setProfileNameTitle(profile.name);
        }
      });
    }

    const useStyles = makeStyles((theme) => ({
      switch_track: {
        backgroundColor: primaryColor,
      },
      switch_base: {
        color: "#FFF",
        "&.Mui-disabled": {
          color: "#FFF"
        },
        "&.Mui-checked": {
          color: primaryColor
        },
        "&.Mui-checked + .MuiSwitch-track": {
          backgroundColor: primaryColor,
        }
      },
      switch_primary: {
        "&.Mui-checked": {
          color: primaryColor,
        },
        "&.Mui-checked + .MuiSwitch-track": {
          backgroundColor: primaryColor,
        },
      },
    }));

    const classes = useStyles();

    function handleChangeProfileName(e){
      const name = e.target.value;
      if(name && name != undefined && name != null && name != "") setProfileName(e.target.value);
      else setProfileName("");
    }

    const updateProfile = async () =>{
      if(profileName && profileName != undefined && profileName != null && profileName != "") {
        const result = await updateProfileApi(idProfile, profileName);

        if (result) {
          setProfileNameTitle(profileName);
        }
      }
    }

    async function getMenuOption(){
      const menuOptionList = await JSON.parse(localStorage.getItem('menu-option'))
      setMenuOption(menuOptionList);
    }

    async function getResourcesApi(){
        const resources = await getPermissionResources();
        setResources(resources);
    }

    async function getProfileResources(){
        const profileResourcesApi = await getProfileResourcesApi(idProfile);
        setProfileResources(profileResourcesApi);
    }

    const changeAllResources = (sectionTo) => {
        resources.forEach( (item) => {
            let isFromOption = item.idSection === sectionTo;
            const isChecked = Boolean(profileResources.filter(obj => {
                return obj.idResource === item.idResource
            }).length)

            if(isFromOption){
                if(isChecked){
                    addProfileResourcesApi(idProfile, item.idResource)
                }else{

                }
            }
        })
    }

    const changeUniqueResource = async (idResource, checked) =>{
        let result;

        if(!checked == true) {
          result = addProfileResourcesApi(idProfile, idResource);
        }

        if(!checked == false){
          result = removeProfileResourcesApi(idProfile, idResource);
        }
    }

    async function confirmDeleteProfile(){
      const returnDelete = await deleteProfile(idProfile);

      if(returnDelete) setRedirect(true);
    }

    function handleCloseAlertDelete(){
        setShowAlertDelete(false);
    }

    function handleOpenAlertDelete(){
      setShowAlertDelete(true);
    }

    function findUserId(name) {
      var result = userList.filter(obj => {
        return obj.name === name
      })

      const hasResult = Boolean(result.length);
      return hasResult ? result[0].idUser : '';
    }

    function startUsers(){
      getUsers().then(
        result => {
          if (result.data.success === true) {
            setUserList(result.data.userList);
            const names = result.data.userList.map(user => { return user.name });
            setUserListName(names)
          }
        }
      ).catch((err) => {
        throw err;
      });
    }

    function handleUserSelect(event) {
      const userName = event.target.value;
      setUserSelected(userName);

      const id = findUserId(userName);
      setValues({
        ...values,
        idUser: id
      })
      setIdUser(id);
    }

    async function addUserIntoProfileBtn(){
      const result = await addUserIntoProfile(idProfile, idUser);

      if(result){
        setUserSelected("");
        getUsersOfProfile();
      }

      setUserSelected("");
    }

    async function getUsersOfProfile(){
      const result = await getProfileUsers(idProfile);

      if(result){
        setProfileUsers(result);
      }
    }

    async function deleteUserOfProfileHandle(idUser){
      const returnDelete = await deleteUserOfProfile(idProfile, idUser);
      getUsersOfProfile();
    }

    useEffect(() => {
      getProfileName();
      getMenuOption();
      getResourcesApi();
      getProfileResources();
      startUsers();
      getUsersOfProfile();
    }, [])

    if (redirect) {
      return <Redirect to='/profiles' />;
    }

    return (
        <div className="Cockpit">
            <div className="header-title-wrapper">
              <div className="header-title">
                <Person style={{ color: '#999999', fontSize: 30 }} />
                <span>
                   { profileNameTitle }
                </span>
              </div>
            </div>

            <div align="right">
                <Button className="blast-button" style={{  backgroundColor: primaryColor, marginRight: '10px', color: "white" }} href="/#/profiles">
                    Voltar
                </Button>
            </div>

            Alterar Perfil <br /><br />
            <div>
              <form className="formUpdateProfile" autoComplete="off">
                <div className="form-row">
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="labelProfiileName">
                        Nome
                      </span>
                    </div>
                    <Col xs={8} sm={8} style={{paddingLeft:0}}>
                      <input
                        type="text"
                        className="form-control"
                        value={profileName}
                        onChange={(e) => handleChangeProfileName(e)}
                      />
                    </Col>
                    <Col xs={12}  sm={2} style={{paddingLeft:0}}>
                      <Button onClick={updateProfile} className="blast-button" style={{  backgroundColor: primaryColor, marginRight: '10px', color: '#FFF', padding:'5px' }}>
                        Salvar
                      </Button>
                    </Col>
                  </div>
                </div>
              </form>

            </div>
          <div align="right" style={{float:"right"}}>
            <Button variant="danger" onClick={handleOpenAlertDelete} style={{ backgroundColor: primaryColor, marginLeft: '100px', color: "white", marginTop: -3 }}>
              <DeleteForever color="white" size={22} style={{ marginRight: 6 }} />
              Excluir Perfil
            </Button>
          </div>
            <hr />
            Associar Recursos
            <br /><br />
            <div className="form-group">
              <div className="">
                  {
                      menuOption.map((item, index) => {
                          let treatedResources = []
                          let hasSelected = false;
                          resources.forEach( (item2) => {
                              let isFromOption = item2.idSection === item.sectionTo;
                              const isChecked = Boolean(profileResources.filter(obj => {
                                  return obj.idResource === item2.idResource
                              }).length)

                              if(isFromOption){
                                  item2 = {
                                      ...item2,
                                      checked: isChecked
                                  }
                                  treatedResources.push(item2);
                                  if(isChecked){ hasSelected=true }
                              }
                          })
                        const name = (<> {item.text} <Switch onChange={changeAllResources} checked={hasSelected} disabled={true} color={primaryColor} classes={{
                          track: classes.switch_track,
                          switchBase: classes.switch_base,
                          colorPrimary: classes.switch_primary,
                        }} /> </>);
                        return (
                            <Acordeon position={index} name={name} >
                                {
                                    treatedResources.map((resource, keyChildren) => {
                                        return (
                                            <div key={resource.idResource} >
                                                {resource.name}
                                                <ToggleSwitch
                                                  key={keyChildren}
                                                  onChange={() => changeUniqueResource(resource.idResource, resource.checked) }
                                                  checked={resource.checked}
                                                  color={primaryColor}
                                                />
                                                <br />
                                            </div>
                                        )
                                    })
                                }
                            </Acordeon>
                        )
                      })
                  }
              </div>
            </div>

            <hr />
              <div className="form-group">
              <br />

              Associar Usuários
              <br /><br />
                <div className="form-row">
                  <div className="col-8  col-sm-6 col-md-6">
                    <FormField
                      type="text"
                      value={userSelected}
                      name="sub-reports-content-users"
                      handlerChange={handleUserSelect}
                      suggestions={userListName}
                    />
                  </div>
                  <div className="col-sm-6 col-md-6 col-5">
                    <Button onClick={addUserIntoProfileBtn} className="blast-button" style={{  backgroundColor: primaryColor, marginRight: '10px', color: '#FFF', marginTop: '2px' }}>
                      <FiUserPlus color="white" size={22} style={{ marginRight: 6 }} />
                      Associar
                    </Button>
                  </div>
                </div>
              </div>
              <div className="form-row">

                  {
                    profileUsers && profileUsers.map((item, index) => {
                      let bg;
                      if(index % 2 === 0) bg = "rgba(101,45,144,0.22)"; else bg = "rgba(101,45,144,0.14)";
                      return (
                          <>
                          <div className="col-12" style={{height: 40, verticalAlign: "middle", padding: 8, borderBottom: "1px solid white", backgroundColor: bg}}>
                            <Person style={{ color: primaryColor, fontSize: 25}} />
                            <span>{ item.name }
                              <HighlightOff id={item.id} onClick={(e) => deleteUserOfProfileHandle(item.idUser)} style={{  color: primaryColor, marginTop: -1 }} /></span>
                          </div>
                          </>
                      )
                    })
                  }

              </div>
          <br /><br /><br />

          <Modal show={showAlertDelete} onHide={handleCloseAlertDelete} animation={false}>
              <Modal.Header closeButton>
                <Modal.Title>Perfil a ser excluído</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Person style={{ color: '#999999', fontSize: 30 }} /> { profileNameTitle }
                <hr />
                Ao optar pela exclusão, automaticamente o sistema removerá <span style={{ color: primaryColor}}>todos</span> os recursos associados e os vínculos dos usuários neste perfil. Podendo impactar em seus logins e permissões de acesso. <br />
                <br />
                Tem certeza que deseja excluir <span style={{ color: primaryColor}}>permanentemente</span> este perfil? <br />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseAlertDelete} style={{backgroundColor: "#6c757d", color: "#FFF"}}>
                  Cancelar
                </Button>
                <Button variant="danger" onClick={confirmDeleteProfile} style={{backgroundColor: primaryColor, color: "#FFF", marginLeft: 10}}>
                  Sim! Desejo excluir.
                </Button>
              </Modal.Footer>
            </Modal>
        </div>
    );
}
