import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import _ from "underscore";
import moment from "moment";
import { Form } from "react-bootstrap";
import Switch from "@material-ui/core/Switch";
import { DatePickerInput } from "rc-datepicker";
import SaveIcon from "@material-ui/icons/Save";
import {
  updateContent
} from "../../services/NexiDigitalAPI";

import AccessTable from "../AccessTable";
import { AuthContext } from "../../context/AuthContext";
import CmsImageUpload from '../../components/CmsImageUpload';
import CmsAutor from '../../components/CmsAutor';
import CmsVideoUpload from '../../components/CmsVideoUpload';
import CmsTitulo from '../../components/CmsTitulo';
import CmsType from '../../components/CmsType';
import CmsTags from '../../components/CmsTags';
import CmsPost from '../../components/CmsPost';
import CmsAudio from '../../components/CmsAudio';
import CmsYoutube from '../../components/CmsYoutube';
import CmsFile from '../../components/CmsFile';

const idVideo = '2';
const idAudio = '1';

const MultiFormat = (props) => {
    const { data, contentTags, permissionApproveContent } = props;
    // Lista de variáveis em ordem alfabética (para bater com a lista recebida do json)
    const [active, setActive] = useState(data.active ||  0);
    const [autor, setAutor] = useState(data.author || "");
    const [autorEmail, setAutorEmail] = useState(data.authorEmail || "");
    const [backConfirmation, setBackConfirmation] = useState(
        data.backConfirmation || ""
    );
    const [backgroundColor, setBackgroundColor] = useState(
        data.backgroundColor || ""
    );
    const [content, setContent] = useState(data.content || "");
    const [contentLocation, setContentLocation] = useState(
        data.contentLocation || ""
    );
    const [description, setDescription] = useState(data.description || "");
    const [endDate, setEndDate] = useState(
        data.endDate === null ? null : new Date(data.endDate)
    );
    const [idContentGroup, setIdContentGroup] = useState(data.idContentGroup);
    const [idContentItem, setIdContentItem] = useState(data.idContentItem);
    const [idContentType, setIdContentType] = useState(data.idContentType);
    const [idSection, setIdSection] = useState(data.idSection);
    const [imagemS3, setImagemS3] = useState(data.image || "");
    const [imageStyle, setImageStyle] = useState(data.imageStyle || "");
    const [initDate, setInitDate] = useState(
        data.initDate === null ? new Date() : new Date(data.initDate)
    );
    const [logoutConfirmation, setLogoutConfirmation] = useState(
        data.logoutConfirmation
    );
    const [referenceLink, setReferenceLink] = useState(data.referenceLink || "");
    const [showBottomMenu, setShowBottomMenu] = useState(data.showBottomMenu);
    const [showTopBar, setShowTopBar] = useState(data.showTopBar);
    const [titulo, setTitulo] = useState(data.text || "");

    const [contentType, setContentType] = useState('')

    const { loginData } = useContext(AuthContext);
    const fourthColor = loginData?.fourthColor;
    const secondaryColor = loginData?.secondaryColor;

    // variáveis de auxílio
    let tagsDeConteudo = [];
    for (let i = 0; i < contentTags.length; i++) {
        tagsDeConteudo.push({ id: i, name: contentTags[i].tag });
    }

    const [imagemOriginal, setImagemOriginal] = useState(data.image || "");
    const [videoOriginal, setVideoOriginal] = useState(data.image || "");
    const [audioOriginal, setAudioOriginal] = useState(data.image || "");
    const [temEndDate, setTemEndDate] = useState(endDate ? true : false);
    const [tags, setTags] = useState(tagsDeConteudo);

    /*********** */
    const [videoDuration, setVideoDuration] = useState(0);

    const [audioDuration, setAudioDuration] = useState(0);
    const [audioType, setAudioType] = useState('audio/mp3');

  /**** */

    const sendData = () => {
        const publishDate = moment().format("YYYY-MM-DD hh:mm:ss.SSS");
        const userId = loginData.userid; // Usuário logado
        const tagList = _.map(tags, function (t) {
            return t.name;
        }).join(";");

        let obj = {
            idContentItem: idContentItem,
            idContentGroup: idContentGroup,
            image: imagemS3,
            text: titulo,
            description: description,
            contentLocation: contentLocation,
            publishDate: publishDate,
            initDate: moment(initDate).format("YYYY-MM-DD hh:mm:ss.SSS"),
            endDate:
                endDate === null
                ? null
                : moment(endDate).format("YYYY-MM-DD hh:mm:ss.SSS"),
            content: content,
            idUserPublisher: parseInt(userId, 10),
            author: autor,
            authorEmail: autorEmail,
            active: parseInt(active),
            idContentType: idContentType,
            tags: tagList,
            backConfirmation: backConfirmation === 1 ? 1 : 0,
            backgroundColor: backgroundColor,
            idSection: idSection,
            imageStyle: imageStyle,
            logoutConfirmation: logoutConfirmation,
            referenceLink: referenceLink,
            showBottomMenu: showBottomMenu,
            showTopBar: showTopBar,
        }
        if(idContentType === idAudio){
            obj.duration = audioDuration
        } else if (idContentType === idVideo) {
            obj.duration = videoDuration
        }

        updateContent(obj)
        .then((res) => {
            if (res.data.success) {
                props.history.goBack();
            }
        })
        .catch((error) => {
            console.log("error", error);
        });
    };

    const usaDataFim = (op) => {
        setTemEndDate(op);

        if (op === false) {
            setEndDate(null);
        }
    };

    return (
        <div>
            <div className="header-title-wrapper">
                <div className="header-title">
                    Multiformato
                </div>
            </div>
            <div className="Video-form">
                <form>
                    <CmsTitulo titulo={titulo} setTitulo={setTitulo} description={description} setDescription={setDescription}/>
                    <CmsTags setTags={setTags} contentTags={contentTags}/>
                    <CmsType contentType={idContentType} setContentType={setIdContentType}/>
                    <CmsImageUpload imagemOriginal={imagemOriginal} imageUrl={imagemS3} changeImage={setImagemS3} idContentItem={idContentItem}/>
                    {(idContentType === '1') && ( <CmsAudio contentLocation={contentLocation} setContentLocation={setContentLocation} 
                        audioDuration={audioDuration} setAudioDuration={setAudioDuration} audioType={audioType} setAudioType={setAudioType} idContentItem={idContentItem}
                        audioOriginal={audioOriginal}/>)}
                    {(idContentType === '2') && ( <CmsVideoUpload contentLocation={contentLocation} setContentLocation={setContentLocation}
                            videoOriginal={videoOriginal} setVideoDuration={setVideoDuration} idContentItem={idContentItem}/>)}
                    {(idContentType === '3') && ( <CmsPost content={content} setContent={setContent} 
                            referenceLink={referenceLink} setReferenceLink={setReferenceLink}/>)}
                    {(idContentType === '7') && ( <CmsYoutube contentLocation={contentLocation} setContentLocation={setContentLocation} />)}
                    {(idContentType === '18') && ( <CmsFile contentLocation={contentLocation} setContentLocation={setContentLocation} />)}
                    <CmsAutor autor={autor} setAutor={setAutor} autorEmail={autorEmail} setAutorEmail={setAutorEmail}/>
                    <div className="form-group">
                        <div className="card overflow-visible">
                            <div
                                style={{ backgroundColor: fourthColor || secondaryColor }}
                                className="card-header template-card-header"
                            >
                                Publicação
                            </div>
                            <div className="card-body template-card-body">
                                <div className="form-row">
                                    {permissionApproveContent && (
                                        <div className="col-md-3">
                                            <Form.Group className="access-table-select">
                                                <Form.Label>
                                                    Status
                                                </Form.Label>
                                                <Form.Control
                                                    as="select"
                                                    value={active}
                                                    onChange={(e) => setActive(e.target.value)}
                                                >
                                                    <option value={0}>Avaliação pendente</option>
                                                    <option value={1}>Aprovado</option>
                                                    <option value={2}>Cancelado</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </div>
                                    )}
                                    <div className="col-md-3">
                                        <Form.Group className="access-table-select">
                                            <Form.Label>
                                                Data de publicação
                                            </Form.Label>
                                            <DatePickerInput
                                                name="initDate"
                                                locale="pt-br"
                                                onChange={(date) => setInitDate(date)}
                                                value={initDate}
                                            />
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="access-table-select form-group">
                                            <label
                                                className="form-label"
                                                htmlFor="tira-do-ar"
                                                style={{ margin: "-3 0" }}
                                            >
                                                Agendar término publicação?
                                            </label>
                                            <br />
                                            <Switch
                                                className="form-group"
                                                id="tira-do-ar"
                                                color="primary"
                                                checked={temEndDate}
                                                onChange={(e) => usaDataFim(e.target.checked)}
                                                inputProps={{ "aria-label": "primary checkbox" }}
                                            />
                                        </div>
                                    </div>
                                    {temEndDate ? (
                                        <div className="col-md-3">
                                            <Form.Group className="access-table-select">
                                                <Form.Label>
                                                    Data de retirada do conteúdo
                                                </Form.Label>
                                                <DatePickerInput
                                                    name="endDate"
                                                    placeholderText="Sem prazo"
                                                    locale="pt-br"
                                                    onChange={(date) => setEndDate(date)}
                                                    value={endDate}
                                                />
                                            </Form.Group>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div
                            style={{ backgroundColor: fourthColor || secondaryColor }}
                            className="card-header template-card-header"
                        >
                            Permissões de acesso ao conteúdo
                        </div>
                        <div className="card-body template-card-body">
                            <AccessTable id={data.idContentItem} />
                        </div>
                    </div>
                    <br />
                    <div className="row" style={{ padding: 5 }}>
                        <div className="col-md-8"></div>
                        <div className="col-md-4">
                            <button
                                type="button"
                                onClick={sendData}
                                className="btn btn-primary"
                            >
                                <SaveIcon /> Salvar
                            </button>
                        </div>
                    </div>
                </form>
                <br />
            </div>
        </div>
    );
};

export default withRouter(MultiFormat);
