import React, { useContext, useState, useRef} from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";

import { AuthContext } from "../../context/AuthContext";
import { uploadImageApi } from "../../services/NexiDigitalAPI";

const CmsAudio = ({contentLocation, setContentLocation, audioDuration, setAudioDuration, audioType, setAudioType, idContentItem, audioOriginal}) => {

    const { loginData } = useContext(AuthContext);
    const audioRef = useRef();
    const fourthColor = loginData?.fourthColor;
    const secondaryColor = loginData?.secondaryColor;

    const [carregaAudio, setCarregaAudio] = useState(false)
    const [audioUploadStatus, setAudioUploadStatus] = useState(false)
    const [mensagemAudioUpload, setMensagemAudioUpload] = useState(false)

    const updateAudioDuration = () => {
        const hasAudio = Boolean(audioRef.current)
        if(hasAudio){
            const duration = Boolean(audioRef.current.duration) ? audioRef.current.duration : 0;
            setAudioDuration(duration);
            return 
        }
        if(contentLocation === ""){
            setAudioDuration(0);
            return ;
        }
        return setTimeout(updateAudioDuration,500)
    }

    const uploadAudio = (arquivo) => {
        setCarregaAudio(true);
        let selectedAudioFile = arquivo.files[0];
        const fileAudioName = "audio" + idContentItem + "-" + Date.now();
        
        const dataAudio = new FormData();
        if (selectedAudioFile) {
            dataAudio.append("fileName", fileAudioName);
            dataAudio.append("fileData", selectedAudioFile);
            // dataAudio.append("profileImage", newAudioFile, newAudioFile.name);
            uploadImageApi(dataAudio)
                .then((response) => {
                    if (response.data.error) {
                        setContentLocation(audioOriginal);
                        setCarregaAudio(false);
                        setAudioUploadStatus(false);
                        setMensagemAudioUpload("Erro ao fazer upload do arquivo");
                    } else {
                        setContentLocation(response.data.location);
                        setCarregaAudio(false);
                        setAudioUploadStatus(true);
                        setMensagemAudioUpload("Audio atualizado com successo");
                    }
                })
                .catch((error) => {
                    // If another error
                    console.log("error", error);
                    setContentLocation(audioOriginal);
                    setCarregaAudio(false);
                    setAudioUploadStatus(false);
                    setMensagemAudioUpload("Erro ao fazer upload do arquivo");
                });
        } else {
            setContentLocation(audioOriginal);
            setCarregaAudio(false);
        }
    };

    return (
        <div className="form-group">
            <div className="card">
                <div
                    style={{ backgroundColor: fourthColor || secondaryColor }}
                    className="card-header template-card-header"
                >
                    Áudio
                </div>
                <div className="card-body template-card-body">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text" id="urlAudio">
                                        url
                                    </span>
                                </div>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={contentLocation}
                                    onChange={(e) => {
                                    const Audio = e.target.value;
                                    setCarregaAudio(true);
                                    window.setTimeout(function () {
                                        setContentLocation(Audio);
                                        setCarregaAudio(false);
                                        setAudioUploadStatus(true);
                                        setMensagemAudioUpload(
                                        "Áudio atualizado com successo"
                                        );
                                    }, 1);
                                    }}
                                />
                            </div>
                            <input
                                type="file"
                                className="form-control-file"
                                id="audio"
                                accept="audio/mp3"
                                onChange={(e) => uploadAudio(e.target)}
                            />
                        </div>
                        {carregaAudio ? (
                            <div className="col-md-6">
                                <CircularProgress />
                            </div>
                        ) : (
                            <div className="col-md-6">
                                <audio ref={audioRef} controls>
                                    <source src={contentLocation} type={audioType} />
                                    Your browser does not support the audio element.
                                </audio>
                                <br />
                                {audioUploadStatus ? (
                                    <span style={{ color: "green" }}>
                                        {mensagemAudioUpload}
                                    </span>
                                ) : (
                                    <span style={{ color: "red" }}>
                                        {mensagemAudioUpload}
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CmsAudio