import React, { useContext, useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import {
  getMenuData,
  setLoginData,
  setUserAgreement,
  logout,
  updateOfferTerms,
} from "../services/NexiDigitalAPI";
import {
  getProfileInfo,
  getColors,
} from "../utils/Utils";
import { Form, Modal, Button } from "react-bootstrap";

export default function TermsModal({ showModal, setShowModal }) {
  const menuData = getMenuData();
  const appDetails = JSON.parse(localStorage.getItem("appDetails")) || null;
  const { loginData } = useContext(AuthContext);
  const thirdColor = loginData?.thirdColor;
  const [termos, setTermos] = useState(false);
  const [communication, setCommunication] = useState(false);
  const [emailNotification, setEmailNotification] = useState(false);
  const [smsNotification, setSmsNotification] = useState(false);
  const [showError, setShowError] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [offersAccepted, setOffersAccepted] = useState(false);
  const [enableOffer, setEnableOffer] = useState(false);
  const [termsPageIndex, setTermsPageIndex] = useState(0);
  const termsPageList = [
    {
      name: "Termos de licença",
      type: "license",
      content: menuData?.agreementTerms,
    },
    {
      name: "Termos de oferta",
      type: "offer",
      content: menuData?.offerTerms,
    },
  ];

  function updateTerms() {
    let menuData = getMenuData();
    menuData.userLicenceTermsAccepted = menuData.agreementVersion;
    let user = getProfileInfo();
    let data = {
      communicationAccepted: communication ? 1 : 0,
      userLicenceTermsAccepted: menuData.agreementVersion,
      // "smsNotification": smsNotification ? 1 : 0,
      // "emailNotification": emailNotification ? 1 : 0,
    };
    setLoginData({
      ...menuData,
      offerTermsAccepted: appDetails?.showOfferTerm === 1 ? 1 : 0
    });
    setUserAgreement(user.idUser, data)
  }

  function _updateOfferTerms(){
    updateOfferTerms()
  }

  console.log('menuData', menuData)

  function toogleNotificationOption(type, bol) {
    if (type === "email") {
      setEmailNotification(bol);
      setSmsNotification(!bol);
    } else {
      setEmailNotification(!bol);
      setSmsNotification(bol);
    }
  }


  function continuar() {
    if (!termos && appDetails?.showOfferTerm) {
      setShowError(true)
      return
    }
    if (appDetails?.showOfferTerm === 1 && !offersAccepted) {
      setShowError(true)
      return
    } else if(appDetails?.showOfferTerm === 1) {
      _updateOfferTerms()
    }
    updateTerms();
    setShowModal(false);
  }

  function setLogout() {
    logout();
    setRedirect(true);
  }

  if (redirect) {
    return <Redirect to="/login" />;
  }

  const noTermsFound = () => {
    return `
      <div style="    text-align: center; padding: 10px 0px; color: gray">
        Nenhum termo encontrado.
      </div>
      `;
  };

  const handlePageIndex = (index) => {
    setTermsPageIndex(index);
    setEnableOffer(true);
  };

  return (
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
          className="modal-main-container"
        >
          <div className="modal-support-blur" />
          <Modal.Header>
            <Modal.Title>{termsPageList[termsPageIndex].name}</Modal.Title>
          </Modal.Header>
          <div className="modal-principal">
            <div className="modal-body-term-container">
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    termsPageList[termsPageIndex].content || noTermsFound(),
                }}
              />
            </div>

            {appDetails?.showOfferTerm === 1 && (
                <div className="terms-page-container">
                  {termsPageList.map((item, index) => (
                    <div
                      className="terms-page-item"
                      onClick={() => handlePageIndex(index)}
                      key={index}
                      style={{
                        backgroundColor:
                          termsPageIndex === index
                            ? thirdColor
                            : "transparent",
                        color: termsPageIndex === index ? "white" : "black",
                      }}
                    >
                      {`${index + 1}. ${item.name}`}
                    </div>
                  ))}
                </div>
              )}
            <div className="terms-checkbox-container" style={{ marginTop: 15, fontWeight: 500 }}>
            {
              appDetails?.showOfferTerm === 1 &&
              <>
                <Form.Group className="terms-form-group" controlId="formBasicCheckbox">
                  <Form.Check
                    onChange={(e) => setTermos(e.target.checked)}
                    checked={termos}
                    type="checkbox"
                    label="Li e aceito os termos de licença."
                  />
                    <span className="terms-check-number">[1]</span>
                  
                </Form.Group>
              </>
            }
              {
                appDetails?.showOfferTerm === 1 && (
                  <Form.Group className="terms-form-group" controlId="formBasicCheckboxOffer">
                    <Form.Check
                      disabled={!enableOffer}
                      onChange={(e) => setOffersAccepted(e.target.checked)}
                      checked={offersAccepted}
                      type="checkbox"
                      label="Li e aceito os termos de oferta."
                    />
                    <span className="terms-check-number">[2]</span>
                  </Form.Group>
                  )
              }
              <Form.Group className="terms-form-group" controlId="formBasicCheckboxCommunication">
                <Form.Check
                  onChange={(e) => setCommunication(e.target.checked)}
                  type="checkbox"
                  label="Aceito receber e-mail ou outras comunicações."
                  checked={communication}
                />
              </Form.Group>
              
              {showError && (
                  <div className="term-error">
                    Você precisa aceitar os termos para continuar.
                  </div>
                )}
              {/* {
                  communication &&
                  <div>
                    <Form.Group controlId="formBasicCheckbox3" style={{ marginBottom: 0 }}>
                      <Form.Check onClick={(e) => toogleNotificationOption('email', e.target.checked)} type="radio" label="E-mail" checked={emailNotification} />
                    </Form.Group>
                    <Form.Group controlId="formBasicCheckbox4" style={{ marginBottom: 0 }}>
                      <Form.Check onClick={(e) => toogleNotificationOption('sms', e.target.checked)} type="radio" label="SMS" checked={smsNotification} />
                    </Form.Group>
                  </div>
                } */}
            </div>
          </div>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setLogout()}>
              Sair
            </Button>
            <Button
              className="btn botao-login"
              style={{ backgroundColor: getColors("menu").secondColor }}
              onClick={() => continuar()}
            >
              Continuar
            </Button>
          </Modal.Footer>
        </Modal>
  )
}
