import React, { useContext, useState, useEffect } from 'react'
import styles from './styles.module.scss'
import {ReactComponent as NextArrow} from '../../../../../assets/images/nextArrow.svg'
import { AuthContext } from "../../../../../context/AuthContext";
import moment from "moment";
import { Link } from "react-router-dom";
import "moment/locale/pt-br.js";
import { DatePicker, ConfigProvider } from "antd";
import ptBR from "antd/es/locale/pt_BR";
import Loading from '../../../../../components/Loading';
const { RangePicker } = DatePicker;

const GenericDashboardCard = ( { title, data, actionFilter, link, loading, classItem = "", filter } ) => {

    
    const [valuePeriod, setValuePeriod] = useState();
    useEffect(()=> {
        if( filter ){
            changePeriod(filter.periodGlobal);
            if ( (filter.dateGlobal).length < 2 ) {
                nullfyDate();                
                setValue(values);
                setDates(values);
                handleFilter(values);
            } else {
                setValue(filter.dateGlobal);
                setDates(filter.dateGlobal);
                handleFilter(filter.dateGlobal);
            }
        }
    },[filter])
    
    const { loginData } = useContext(AuthContext)
    const fourthColor = loginData.fourthColor;
    const path = window.location.hash.split('?')[0]
    const [dates, setDates] = useState([]);

    const [values, setValues] = useState({
        startDate: moment(new Date()).subtract(6, "days").format("YYYY-MM-DD"), 
        endDate: moment(new Date()).format("YYYY-MM-DD")
    })

    const [firstElem, setFirstElem] = useState({
        date: moment(new Date()).subtract(6, "days").format("DD/MM/YYYY"),
        day: moment(new Date()).subtract(6, "days").format("dddd"),
    });

    const [lastElem, setLastElem] = useState({
        date: moment(new Date()).format("DD/MM/YYYY"),
        day: moment(new Date()).format("dddd"),
    });

    const [value, setValue] = useState([
        moment(firstElem.date, ["DD/MM/YYYY"]),
        moment(new Date(), ["DD/MM/YYYY"]),
    ]);
    const [hideData, setHideData] = useState(true);

    const linkStyle = {
        color:fourthColor, 
        fill:fourthColor,
    }

    const changePeriod = (value) => {
        setValuePeriod(value);
        const eventValue =  value
        switch(eventValue){
            case '0':
                setHideData(true)
                setValues({
                    startDate: moment(new Date()).subtract(6, "days").format("YYYY-MM-DD"), 
                    endDate: moment(new Date()).format("YYYY-MM-DD")
                })
                break;
            case '1':
                setHideData(true)
                setValues({
                    startDate: moment(new Date()).subtract(29, "days").format("YYYY-MM-DD"), 
                    endDate: moment(new Date()).format("YYYY-MM-DD")
                })
                break;
            case '2':
                setHideData(false)
            break;
            default:
                break;
        }
    }

    const editData = (start, end) =>{
        setFirstElem({
            date: start.format("DD/MM/YYYY"),
            day: start.format("dddd"),
        });
        setLastElem({
            date: end.format("DD/MM/YYYY"),
            day: end.format("dddd"),
        });
    }

    const nullfyDate = () => {
        setLastElem({
            date: null,
            day: null,
        });
        setLastElem({
            date: null,
            day: null,
        });
    }

    function handleFilter(val = false) {

        let _value = val || [values.startDate, values.endDate];
        if (_value && _value[0] && _value[1]) {
            setValue(val)
            setValues({
                startDate: moment(val[0]).format("YYYY-MM-DD"), 
                endDate: moment(val[1]).format("YYYY-MM-DD")
            })
        } else {
            nullfyDate()
        }
    }

    const disabledDate = (current) => {
        return current && current > moment();
    };

    const styleData = (hideData) ? {display:'none'} : {display:'inline-block'} ;

    const loadingData = (
        <div className={styles.carregando}>
            <Loading containerStyle={{height: '150px'}}/>
        </div>
    );

    useEffect( () => {
        let filterGlobal = values;
        if( filter.idGroupGlobal ){  
            filterGlobal.idGroup = parseInt(filter.idGroupGlobal);
        }
        actionFilter(filterGlobal)
    }, [])
    useEffect( () => {
        let filterGlobal = values;
        if( filter.idGroupGlobal ){  
            filterGlobal.idGroup = parseInt(filter.idGroupGlobal);
        }
        actionFilter(filterGlobal)
    }, [values])

    return (
        <div className={styles.half_block + " " + classItem} >
            <div className={ (hideData? styles.report_container : styles.report_containerShowDate )}>
                <div className={styles.reportTitle}>
                    {title}
                </div>
                <div className={styles.reportData}>
                    {loading ? loadingData : data}
                </div>
                <div className={ styles.reportBottom }>   
                    <div className={styles.reportFilter}>
                        <div className={styles.selectDiv}>
                            <select value={valuePeriod} className={styles.selectPediodSelection} onChange={(event)=>changePeriod(event.target.value)}>
                                <option value="0">Últimos 7 dias</option>
                                <option value="1">Últimos 30 dias</option>
                                <option value="2">Qualquer período</option>
                            </select>
                        </div>
                        <div className={styles.dateDiv} style={styleData}>
                            <ConfigProvider locale={ptBR}>
                                <RangePicker
                                    value={value}
                                    disabledDate={disabledDate}
                                    onCalendarChange={(val) => {
                                        if (
                                            val &&
                                            val.length === 2 &&
                                            moment(val[1]) > moment()
                                        ) {
                                            val[1] = moment();
                                        }
                                        setDates(val);
                                        if (!val) {
                                            nullfyDate()
                                        } else {
                                            handleFilter(val);
                                        }
                                    }}
                                    defaultValue={[
                                        values.startDate,
                                        values.endDate,
                                    ]}
                                    onChange={(val) => {
                                        
                                    }}
                                    format={["DD/MM/YYYY", "DD/MM/YY", "MM/YY"]}
                                    size={"large"}
                                />
                            </ConfigProvider>
                        </div>
                    </div>
                    <Link 
                        to={{
                            search: link,
                        }}
                        className={styles.reportLink} 
                        style={linkStyle} 
                    >
                        Relatório Completo <NextArrow />
                    </Link>
                </div>
            </div>
        </div>
    );

}

export default GenericDashboardCard;