import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import {
  getGroups,
  createGroup,
  removeGroup,
} from "../../../services/NexiDigitalAPI";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import _ from "underscore";
import FilterResults from "react-filter-search";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Tooltip } from "@material-ui/core";
import { AuthContext } from "../../../context/AuthContext";
import { Button, Modal } from "react-bootstrap";
import Loading from "../../../components/Loading";
import { getMenuRoutes } from "../../../utils/Utils";

const Groups = () => {
  const [lista, setLista] = useState([]);
  const [currentGroup, setCurrentGroup] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [loadingGroups, setLoadingGroups] = useState(true);

  const { loginData } = useContext(AuthContext);
  const secondaryColor = loginData.secondaryColor;

  const isMountedRef = useRef(null);

  /* [Permission Module] - Permissions states */
  const [permissionAddUserIntoGroup, setPermissionAddUserIntoGroup] = useState(false);

  /* [Permission Module] - Menu Options */
  async function getMenuOption() {
    const menuOptionList = getMenuRoutes();

    const objCurrentMenu = menuOptionList.filter(
      (menu) => menu.text === "Cadastro de Usuários"
    );

    if (Boolean(objCurrentMenu.length)) {
      const resources = objCurrentMenu.map((menu, index) => menu.resource);

      getPermissionsPage(resources);
    }
  }

  /* [Permission Module] - Permission attrs in page by login data information */
  function getPermissionsPage(resources) {
    try {
      if (
        Boolean(
          resources[0].filter(
            (menu) => menu.name === "Inserir usuário no grupo"
          ).length
        )
      )
        setPermissionAddUserIntoGroup(true);
    } catch (error) {
      console.log('Falha ao buscar permissões de Grupos');
    }
  }

  useEffect(() => {
    /* [Permission Module] - Function calls */
    getMenuOption();

    isMountedRef.current = true;
    getGroups()
      .then((res) => {
        if (isMountedRef.current) {
          setLista(_.sortBy(res.data.groups, "title"));
          setLoadingGroups(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return () => (isMountedRef.current = false);
  }, []);

  const handleAdd = (event) => {
    event.preventDefault();
    if (currentGroup.length > 0) {
      createGroup(currentGroup).then((res) => {
        if (res.data.success) {
          const novoGrupo = {
            idGroups: res.data.idGroup[0],
            title: currentGroup,
          };
          setLista(_.sortBy([...lista, novoGrupo], "title"));
        }
      });
    }
  };

  const handleDel = (id) => {
    removeGroup(id).then((res) => {
      if (res.data.success) {
        setLista(lista.filter((g) => g.idGroups !== id));
      }
      if (
        !res.data.success &&
        res.data.message === "existing users at group!"
      ) {
        setShowErrorModal(true);
      }
    });
  };

  return (
    <div className="groups">
      <div className="header-title-wrapper">
        <div className="header-title">Grupos</div>
      </div>

      <Modal
        show={showErrorModal}
        onHide={() => setShowErrorModal(false)}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Não foi possível remover o grupo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Este grupo possui usuários ativos, por favor remova os usuários antes
          de remover o grupo
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowErrorModal(false)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Cadastra novo grupo */}
      <div className="post-form">
        <form onSubmit={handleAdd}>
          <div className="form-group">
            <div className="card">
              <div
                className="card-header template-card-header"
                style={{
                  color: "#FFF",
                  backgroundColor: secondaryColor,
                }}
              >
                Cadastrar novo Grupo
              </div>
              <div className="card-body template-card-body">
                <div className="row">
                  <div className="col-md-10 input-container">
                    <input
                      type="text"
                      className="form-control input-default"
                      id="group"
                      aria-describedby="group"
                      value={currentGroup}
                      onChange={(e) => setCurrentGroup(e.target.value)}
                    />
                  </div>
                  <div className="col-md-2">
                    <button type="submit" className="btn btn-secondary">
                      <AddCircleIcon /> Novo Grupo
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      {/* Lista grupos */}
      {loadingGroups ? (
        <Loading>Carregando grupos</Loading>
      ) : (
        <div className="card">
          <div
            className="card-header template-card-header"
            style={{
              color: "#FFF",
              backgroundColor: secondaryColor,
            }}
          >
            Lista de Grupos
          </div>
          <div className="card-body template-card-body">
            <FilterResults
              value={currentGroup}
              data={lista}
              renderResults={(results) => (
                <ul className="list-group">
                  {results.map((item, index) => {
                    return (
                      <div key={item.idGroups}>
                        <li className="list-group-item">
                          {item.title}
                          <Tooltip title="Remover Grupo" placement="top">
                            <span
                              className="btn btn-sm float-right"
                              onClick={() => handleDel(item.idGroups)}
                            >
                              <DeleteForeverOutlinedIcon
                                style={{ color: "#dc3545" }}
                              />
                            </span>
                          </Tooltip>
                          {permissionAddUserIntoGroup && (
                            <Tooltip title="Usuários" placement="top">
                              <span className="btn btn-sm float-right">
                                <Link
                                  to={`/configuracao/grupos/${item.idGroups}/${item.title}`}
                                >
                                  <PeopleOutlineIcon
                                    style={{ color: "#6c757d" }}
                                  />
                                </Link>
                              </span>
                            </Tooltip>
                          )}
                        </li>
                      </div>
                    );
                  })}
                </ul>
              )}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Groups;
