import React, { useState, useEffect, useCallback, useContext } from "react";
import { Button, Form, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import MaskInput from "react-maskinput";
import { MdEdit } from "react-icons/md";
import { BiArrowBack } from "react-icons/bi";
import Loading from '../../components/Loading'
import axios from "axios";

import { appDetails } from '../../services/NexiDigitalAPI'

const api_url = process.env.REACT_APP_BACKEND_API;

export default function UserForm({ submitResult, token, notAuto }) {
  const history = useHistory();
  const goBack = useCallback(() => history.push("/user"), [history]);
  const [loadingScreen, setLoadingScreen] = useState(true);
  const [loadingUser, setLoadingUser] = useState(false);
  const defaultData = {
    email: '',
    nome: '',
    fone: '',
    cpf: '',
    admin: false,
    password: '',
    userType: 1,
    userLicenceTermsAccepted: 0,
    communicationAccepted: 0,
    guestCompany: " ",
    guestCnpj: " ",
    active: 1,
    photo: "",
  }

  const [data, setData] = useState(defaultData);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [wrongPassword, setWrongPassword] = useState(false);
  const [passwordEqualError, setPasswordEqualError] = useState(false);
  const [errorSave, setErrorSave] = useState(false);
  const [details, setDetails] = useState([]);
  const [messageError, setMessageError] = useState("");
  const hasMessageError = Boolean(messageError)

  const primaryColor = details ? details.primaryColor : null;
  const url = (window.location.origin === 'http://localhost:3000') ? 
              'homologadmin.blast.etc.br' : 
              window.location.origin.replace(/https:\/\/|http:\/\//g,"");

  useEffect(() => {
    window.scrollTo(0, 0);
    _appDetails();
  },[]);

  function _appDetails() {

    appDetails(url).then(res => {
      if (res.data.app.length == 0) {
        setLoadingScreen(false)
        return
      }
      setDetails(res.data.app[0])
      setLoadingScreen(false)
    })
    .catch(err => {
      setLoadingScreen(false)
    })
    .finally(() => {
      setLoadingScreen(false)
    })
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setMessageError('');
    setWrongPassword(false);
    setPasswordEqualError(false);
    setErrorSave(false);

    //validar senha (8 digit and a number)
    let passwordMatch = /^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{8,}$/gm.test(
        data.password
    );
    if (data.password && !passwordMatch) {
      setWrongPassword(true);
      return;
    }

    //validar confirmação de senha
    if (data.password && data.password != confirmPassword) {
      setPasswordEqualError(true);
      return;
    }

    submit(data);
  }

  async function submit(obj) {
    try {
      const isAdmin = obj.admin === true ? 1 : 0;
      setLoadingUser(true);
      let objeto = {
        email: obj.email,
        name: obj.nome,
        phone: obj.fone,
        cpf: obj.cpf,
        userType: obj.userType,
        userLicenceTermsAccepted: obj.userLicenceTermsAccepted,
        communicationAccepted: obj.communicationAccepted,
        guestCompany: obj.guestCompany,
        guestCnpj: obj.guestCnpj,
        admin: isAdmin,
        active: obj.active,
        photo: obj.photo,
      }
      if(obj.password){
        objeto.password= obj.password;
      }
      axios
        .post(
          `${api_url}/users`, objeto,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            "Content-Type": "application/json",
          }
        )
        .then(({ data }) => {
          setLoadingUser(false);
          if (data.success) {
            submitResult();
          } else{
            setMessageError(data.message);
          }
        })
        .catch((e) => {
          setLoadingUser(false);
          let message = (e.response.data.message) ? e.response.data.message : e.response.data.error
          if( message === "Not Authorized"){ message = "Não Autorizado"; }
          setMessageError(message);
          console.log("Erro ao cadastrar usuário", e);
        });
    } catch (e) {
      alert(e.message);
    }
  }

  return (
    <>
    {
      loadingScreen ?
      (
        <div>
          <Loading />
        </div>
      )
      :
      (<div>
        <div style={{marginBottom: '80px'}} className="header-title-wrapper">
          <div className="header-title">{notAuto ? "Registro de usuário" : "Auto inscrição"}</div>
        </div>
        <div className="user-edit-container">
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Digite o seu nome"
                value={data.nome}
                onChange={(e) => setData({ ...data , nome: e.target.value})}
              />
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="Digite o seu email"
                value={data.email}
                onChange={(e) => setData({ ...data , email: e.target.value})}
              />
            </Form.Group>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Form.Group className="user-edit-inside-part">
                <Row>
                  <Col lg="6" md="6" xs="12" sm="7">
                    <Form.Label>Senha(Opcional)</Form.Label>
                    <Form.Control
                      className={wrongPassword ? "border-red" : "border-none"}
                      type="password"
                      autoComplete="new-password"
                      placeholder="Senha"
                      onChange={(e) => setData({ ...data , password: e.target.value})}
                    />
                    <Form.Text className="text-muted">
                      Pelo menos 8 caracteres
                    </Form.Text>
                    <Form.Text className="text-muted">
                      Pelo menos uma letra
                    </Form.Text>
                    <Form.Text className="text-muted">
                      Pelo menos um número
                    </Form.Text>
                    <Form.Group>
                      *Se  o campo senha não for preenchido, um email com instruções para troca de senha será enviado. Caso uma senha seja passada, o email não será enviado.
                    </Form.Group>
                  </Col>
                  <Col lg="6" md="6" xs="12" sm="7">
                    <Form.Label>Confirmar senha</Form.Label>
                    <Form.Control
                      
                      className={
                        passwordEqualError ? "border-red" : "border-none"
                      }
                      type="password"
                      disabled={data.password.length == 0}
                      autoComplete="new-password"
                      placeholder="Senha"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </Col>
                </Row>
              </Form.Group>
            </div>
            
            <Form.Group controlId="formCelularLabel">
              <Form.Label>Celular </Form.Label>
              <br />

              <MaskInput
                required
                className="mask-input"
                alwaysShowMask
                mask={"(00) 00000-0000"}
                size={20}
                showMask
                maskChar="_"
                placeholder="Digite o número do seu celular"
                value={data.fone}
                onChange={(e) => setData({ ...data , fone: e.target.value})}
              />
            </Form.Group>

            <Form.Group controlId="formCPFLabel">
              <Form.Label style={{ position: "relative" }}>
                <span className="add-user-optional">(opcional)</span>
                CPF
              </Form.Label>
              <br />
              <MaskInput
                className="mask-input"
                alwaysShowMask
                mask={"000.000.000-00"}
                size={20}
                showMask
                maskChar="_"
                placeholder="Digite o número do seu CPF"
                value={data.cpf}
                onChange={(e) => setData({ ...data , cpf: e.target.value})}
              />
            </Form.Group>

            {notAuto &&
                (<Form.Group controlId="formAdmin">
                  <Form.Check
                    style={{ display: "flex", alignItems: "center" }}
                    type="checkbox"
                    label="Tornar Administrador"
                    defaultChecked={data.admin}
                    onChange={(e) => setData({ ...data , admin: !data.admin}) }
                  />
                </Form.Group>
              )}
            <div className="mb-2" style={{ display: "flex", marginTop: 30 }}>
              {notAuto &&
                (<Button
                  onClick={goBack}
                  variant="secondary"
                  style={{ marginRight: 15 }}
                  size="sm"
                >
                  <div>
                    <BiArrowBack
                      size={22}
                      style={{ marginBottom: 3, marginRight: 10 }}
                    />
                    Voltar
                  </div>
                </Button>)
              }

              <Button
                className="blast-button"
                style={{ backgroundColor: primaryColor }}
                type="submit"
                size="sm"
              >
                {!loadingUser ? (
                  "Efetuar o Registro"
                ) : (
                  <div style={{ display: "flex" }}>
                    <div
                      className="loader loader-white"
                      style={{ marginRight: 10 }}
                    />
                    Criando usuário...
                  </div>
                )}
              </Button>
            </div>

            <div style={{ marginTop: 10 }}>
              <div style={{ color: "red" }}>
                {(wrongPassword && !hasMessageError) && "Senha não segue os padrões."}
                {(passwordEqualError && !hasMessageError) && "Confirme sua senha corretamente."}
                {(errorSave && !hasMessageError) && "Erro ao salvar usuário"}
                {(hasMessageError) && messageError}
              </div>
            </div>
          </Form>
        </div>
      </div>)}
    </>
  );
}
