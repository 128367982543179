import React, { useState, useEffect } from 'react';
import {
  Col,
  Form,
} from "react-bootstrap";
import {
  FaFilter,
} from "react-icons/fa";
import "rc-datepicker/lib/style.css";
import  { getUsersApi,getListCampaigns } from "../../../../../services/NexiDigitalAPI";
import FiltroWrapper from '../../../Components/FiltroWrapper';
import styles from './styles.module.scss';
import Select from 'react-select'
import ButtonWithLoading from '../../../../../components/ButtonWithLoading';

const Filtro = ({ actionFilter, loading }) => {
    const [values, setValues] = useState({
        name:'',
        idUser: 0
    });
    
    const initialSelected = {
      value: 0,
      label: ''
    }
    
    
    const [userList, setUserList] = useState([]);
    const [campanhaList, setCampanhaList] = useState([]);
    const [selected, setSelected] = useState(initialSelected)
    const [selectedCampanha, setSelectedCampanha] = useState(initialSelected)
    const [options, setOptions] = useState([]);
    const [optionsCampanha, setOptionsCampanha] = useState([]);

    async function startUsers(){
      const usersApi = await getUsersApi()
      setUserList(usersApi);
      const treatedOptions = usersApi.map( (item) => { return { value: item.idUser, label: item.name } })
      setOptions(treatedOptions)
    }
    async function startCampanha(){
        const campanhaApi = await getListCampaigns();
        setCampanhaList(campanhaApi);
        const treatedOptions = campanhaApi.map( (item) => { return { value: item.idgamification_campaign, label: item.name } })
        setOptionsCampanha(treatedOptions)
    }
    async function changeCampanha(campanha) {
    setSelectedCampanha(campanha)
      const campanhaInfo = await findCampanha(campanha.value);
      const hasUserInfo = Boolean(campanhaInfo.length);
      if(hasUserInfo){
          setValues({
              ...values,
              idCampanha: campanhaInfo[0].idgamification_campaign,
              name:campanhaInfo[0].name
          })
      }
    }

    function handleFilter(){
        actionFilter(values);
    }

    const findUser = (idUser) => {
      const first =  userList.filter( obj => obj.idUser === idUser )
      return first
    }

    const findCampanha= (id) => {
        const first =  campanhaList.filter( obj => obj.idgamification_campaign === id )
        return first
      }

    const customStyles = {
      control: base => ({
          ...base,
          borderRadius: "0 0 1px 1px",
          border: "1px solid #ced4da",
      })
    }

    const changeUser = async (user) => {
      setSelected(user)
      const userInfo = await findUser(user.value);
      const hasUserInfo = Boolean(userInfo.length);
      if(hasUserInfo){
          setValues({
              ...values,
              idUser: userInfo[0].idUser
          })
      }
    }

    useEffect(() => {
        startUsers();
        startCampanha();
    },[]);

    return (
        <FiltroWrapper>
        <Form style={{ marginTop: 30, marginBottom: 30 }}>
          <Form.Row className="align-items-end">
            <Col xs="12" md="6" lg="4" className={styles.formSpace}>
            <Form.Label htmlFor="dateStart">Campanha</Form.Label>
                <Select 
                    options={optionsCampanha} 
                    id="cadastroProdutoModalProduto"
                    value={selectedCampanha}
                    onChange={changeCampanha}
                    name="idUser"
                    styles={customStyles}
                />
            </Col>
            <Col xs="12" md="6" lg="4" className={styles.formSpace}>
              <Form.Label htmlFor="dateStart">Usuário</Form.Label>
              <Select 
                  options={options} 
                  id="cadastroProdutoModalProduto"
                  value={selected}
                  onChange={changeUser}
                  name="idUser"
                  styles={customStyles}
              />
            </Col>
            <Col xs="12" md="4" lg="2" className={styles.formSpace}>
              <Form.Group className="mb-0">
                <ButtonWithLoading 
                    onClickHandler={handleFilter}
                    loading={loading}
                    text={( <>Aplicar filtro <FaFilter style={{ marginLeft: 5 }} /> </> )}
                />
              </Form.Group>
            </Col>
          </Form.Row>
        </Form>
      </FiltroWrapper>
    );
}

export default Filtro;