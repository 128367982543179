import styles from './styles.module.scss';
import React from 'react';

const FilterWrapper = ({ children }) => {
    return (
        <div className={ `report-filter-container ${styles.FiltroWrapper}` }>
            { children }
        </div>
    )
}

export default FilterWrapper;