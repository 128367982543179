// Basic
import React from "react";
import Listcampaigns from './SubReports/ListCampaigns';
import RankingUser from './SubReports/RankingUser';
import ReportWrapper from '../Components/ReportWrapper';
import ReportListTitle from '../Components/ReportListTitle';
import DetailsRankingUser from "./SubReports/DetailsRankingUser";


const ReportGamification = () => {

  const reportList = [
    (index, expanded) => <Listcampaigns index={index} expanded={expanded} key={index}/>,
    (index, expanded) => <RankingUser index={index} expanded={expanded} key={index}/>,
    (index, expanded) => <DetailsRankingUser index={index} expanded={expanded} key={index}/>
  ]

  return (
    <ReportWrapper>
      <ReportListTitle>
        Lista de Relatórios
      </ReportListTitle>
      {reportList.map((report, index) => report(index, false))}
    </ReportWrapper>
  )
}

export default ReportGamification