import React, { createContext, useState } from 'react'

export const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    const app_id = localStorage.getItem('app_id')
    const secondaryColor = localStorage.getItem('secondaryColor')
    const thirdColor = localStorage.getItem('thirdColor')
    const fourthColor = localStorage.getItem('fourthColor')
    const isAdmin = parseInt(localStorage.getItem('is-admin'))
    const userid = localStorage.getItem('userid')
    const menuData = JSON.parse(localStorage.getItem('menu-data'))
    const token = localStorage.getItem('token')
    const primaryColor = localStorage.getItem('primaryColor')
    const id = localStorage.getItem('id')

    if (token) {
      return {
        token,
        loginData: {
          app_id,
          secondaryColor,
          thirdColor,
          fourthColor,
          isAdmin,
          userid,
          menuData,
          primaryColor,
          id
        }
      }
    }
  })

  const signIn = async (token, data) => {
    localStorage.setItem('app_id', data.idApp)
    localStorage.setItem('secondaryColor', data.secondaryColor)
    localStorage.setItem('thirdColor', data.thirdColor)
    localStorage.setItem('fourthColor', data.fourthColor)
    localStorage.setItem('is-admin', data.admin)
    localStorage.setItem('userid', data.idUser)
    localStorage.setItem('menu-data', JSON.stringify(data))
    localStorage.setItem('token', token)
    localStorage.setItem('primaryColor', data.primaryColor)
    localStorage.setItem('id', data.idUser)
    setData({
      token,
      loginData: {
        app_id: data.idApp,
        secondaryColor: data.secondaryColor,
        thirdColor: data.thirdColor,
        fourthColor: data.fourthColor,
        isAdmin: data.admin,
        userid: data.idUser,
        menuData: data,
        primaryColor:  data.primaryColor,
        id: data.idUser
      }
    })
  }

  const signOut = async () => {
    setData({})
    localStorage.clear()
  }

  return (
    <AuthContext.Provider
      value={{ loginData: data?.loginData, signIn, signOut}}
    >
      { children }
    </AuthContext.Provider>
  )
}

export default AuthProvider
