import React, {useEffect, useState, useContext} from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";

import SemImagem from "../../assets/images/sem-imagem.jpg";
import {
    uploadImageApi,
  } from "../../services/NexiDigitalAPI";
  
import { AuthContext } from "../../context/AuthContext";

const CmsImageUpload = ({imagemOriginal, imageUrl, changeImage, idContentItem}) => {

    const [carregaImagem, setCarregaImagem] = useState(false)
    const [imagemUploadStatus, setImagemUploadStatus] = useState(false)
    const [mensagemImagemUpload, setMensagemImagemUpload] = useState(false)

    const { loginData } = useContext(AuthContext);
    const fourthColor = loginData?.fourthColor;
    const secondaryColor = loginData?.secondaryColor;

    const uploadImagem = (arquivo) => {
        setCarregaImagem(true);
        let selectedImageFile = arquivo.files[0];
        const fileImageName = "thumb" + idContentItem + "-" + Date.now();
    
        const dataImage = new FormData(); 
        if (selectedImageFile) {
            dataImage.append("fileName", fileImageName);
            dataImage.append("fileData", selectedImageFile);
            uploadImageApi(dataImage)
                .then((response) => {
                    if (response.data.error) {
                        changeImage(imagemOriginal);
                        setCarregaImagem(false);
                        setImagemUploadStatus(false);
                        setMensagemImagemUpload("Erro ao fazer upload do arquivo");
                    } else {
                        changeImage(response.data.location);
                        setCarregaImagem(false);
                        setImagemUploadStatus(true);
                        setMensagemImagemUpload("Imagem atualizada com successo");
                    }
                })
                .catch((error) => {
                    // If another error
                    console.log("error", error);
                    changeImage(imagemOriginal);
                    setCarregaImagem(false);
                    setImagemUploadStatus(false);
                    setMensagemImagemUpload("Erro ao fazer upload do arquivo");
                });
        } else {
            changeImage(imagemOriginal);
            setCarregaImagem(false);
        }
    };


    return (
        <div className="form-group">
            <div className="card">
                <div
                    style={{ backgroundColor: fourthColor || secondaryColor }}
                    className="card-header template-card-header"
                >
                    Imagem
                </div>
                <div className="card-body template-card-body">
                    <div className="row">
                        <div className="col-md-6">
                            <input
                                type="file"
                                className="form-control-file"
                                id="imagem"
                                accept="image/png, image/jpeg, image/gif"
                                onChange={(e) => uploadImagem(e.target)}
                            />
                        </div>
                        {carregaImagem ? (
                            <div className="col-md-6">
                                <CircularProgress />
                            </div>
                        ) : (
                            <div className="col-md-6">
                                <img
                                    src={imageUrl || SemImagem}
                                    alt="Imagem"
                                    className="img-fluid img-thumbnail image-effect"
                                    style={{ height: 130 }}
                                />
                                <br />
                                <br />
                                {imagemUploadStatus ? (
                                    <span style={{ color: "green" }}>
                                        {mensagemImagemUpload}
                                    </span>
                                ) : (
                                    <span style={{ color: "red" }}>
                                        {mensagemImagemUpload}
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CmsImageUpload