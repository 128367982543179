import React, {useContext} from 'react'
import { Select, MenuItem, } from '@material-ui/core';

import { AuthContext } from "../../context/AuthContext";

const CmsType = ({contentType, setContentType}) => {

    const { loginData } = useContext(AuthContext);
    const fourthColor = loginData?.fourthColor;
    const secondaryColor = loginData?.secondaryColor;

    return(
        <div className="form-group">
            <div className="card">
                <div
                    style={{ backgroundColor: fourthColor || secondaryColor }}
                    className="card-header template-card-header"
                >
                    Tipo de formato
                </div>
                <div className="card-body template-card-body">
                    <div className="row">
                        <div className="col-md-12">
                            <Select
                                label="Tipo de formato"
                                onChange={(e)=>{setContentType(e.target.value)}}
                                value={contentType}
                                variant='filled'
                                style={{width: '100%', backgroundColor: '#fff', border: '1px solid rgba(0, 0, 0, 0.125)'}}
                                placeholder='Opções'
                            >
                                <MenuItem value='1'>Áudio</MenuItem>
                                <MenuItem value='2'>Vídeo</MenuItem>
                                <MenuItem value='3'>Post</MenuItem>
                                <MenuItem value='7'>Youtube Playlist</MenuItem>
                                <MenuItem value='18'>Arquivo</MenuItem>
                            </Select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default CmsType