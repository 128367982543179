import React, { useState, useEffect } from "react";
import Table from './Table';
import ReportAcordeon from '../../../Components/ReportAcordeon'
import { 
    listTopContentItemProgress
} from "../../../../../services/NexiDigitalAPI";


const TopConcluded = ({ index, expanded }) => {
    const name = "Conteúdos com maior progresso"
    const description = "Exibe os conteúdos com maior progresso.";

    const [loading, setLoading] = useState(false);
    const [dados, setDados] = useState([]);

    const getData = async () => {
        setLoading(true);
        const responseData = await listTopContentItemProgress();
        setDados(responseData);
        setLoading(false);
    }

    useEffect(()=>{
        getData()
    },[])

    const result = (
        <Table 
            dados={ dados } 
            loading={ loading }
        />
    );
    
    return (
        <ReportAcordeon
            name={name}
            description={description}
            result={result}
            index={ index }
            expanded={expanded}
        />
        
    )
}

export default TopConcluded;