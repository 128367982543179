import React from 'react';
import styles from './styles.module.scss';
import { MdEdit } from 'react-icons/md';
import { FaMinusCircle } from 'react-icons/fa';
import moment from "moment";
import StandartDataTable from '../../../../../components/StandartDataTable';

const Table = ({ List, editOffer, RemoveList, loading}) => {

    const columns = [
        { name: "Imagem", selector: "image", sortable: true, },
        { name: "Nome", selector: "title", sortable: true },
        { name: "Preço", selector: "price", sortable: true, },
        { name: "Data final", selector: "endDate", sortable: true, },
        { name: "Ações", selector: "action", sortable: false, }
    ];

    const treatedList = List.map( (item) => {
        const hasEndDate = Boolean(item.endDate)
        return {
            ...item,
            image:(
                <img src={item.imageURL} alt="" height="75" width="75" style={{margin: "5px 0"}}/>
            ),
            endDate: hasEndDate ? moment(item.endDate).format("DD/MM/YYYY") : "-",
            price: item.price.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}),
            action: (
                <>
                    <button className={styles.actionButton} onClick={() => editOffer(item)}>
                        <MdEdit className={styles.actionIcon} />
                    </button>
                    { false && <button className={styles.actionButton} >
                        <FaMinusCircle className={styles.actionIcon} />
                    </button>}
                </>
            ),
        }
    })

    return (
        <div className="report-table-container">
            <StandartDataTable
                columns={columns}
                data={treatedList}
                loading={ loading }
            />
        </div>
    );
}

export default Table;