import React, { useState, useEffect, useRef, useContext } from "react";
import { Redirect, Link, NavLink } from 'react-router-dom'
import { contentGroups, contentTypes } from "../../services/NexiDigitalAPI";
import '../../assets/css/TelaPrincipal.css'
import { transformText, capitalizeFirstLetter, urlExtension, getMenuImage, getTypeName } from '../../utils/Utils';
import MicIcon from '@material-ui/icons/Mic';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import AssignmentIcon from '@material-ui/icons/Assignment';
import TimelineIcon from '@material-ui/icons/Timeline';
import YouTubeIcon from '@material-ui/icons/YouTube';
import SubjectIcon from '@material-ui/icons/Subject';
import CallIcon from '@material-ui/icons/Call';
import DescriptionIcon from '@material-ui/icons/Description';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import HelpIcon from '@material-ui/icons/Help';
import { AuthContext } from "../../context/AuthContext";
import Loading from "../../components/Loading";


function GestaoAgenda(props) {

    const [redirect, setRedirect] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)

    const { loginData } = useContext(AuthContext)
    const primaryColor = loginData.primaryColor

    function getContentGroups(){
        contentGroups().then(res => {
            let array = res.data.contentGroups;
            localStorage.setItem("contentGroups", JSON.stringify(array))
            setData(array)
        }).catch(err => {
            console.log(err)
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        //getContentGroups()
    }, [])

    if (redirect) {
        return <Redirect to='/login' />;
    }

    function getIconByType(type) {
        switch (type) {
            case 1:
                return <MicIcon style={{ color: 'white', fontSize: 50 }}/>
            case 2:
                return <OndemandVideoIcon style={{ color: 'white', fontSize: 50 }}/>
            case 3:
                return <AssignmentIcon style={{ color: 'white', fontSize: 50 }}/>
            case 4:
                return <TimelineIcon style={{ color: 'white', fontSize: 50 }}/>
            case 5:
                return <DescriptionIcon style={{ color: 'white', fontSize: 50 }}/>
            case 6:
                return <YouTubeIcon style={{ color: 'white', fontSize: 50 }}/>
            case 7:
                return <SubjectIcon style={{ color: 'white', fontSize: 50 }}/>
            case 8:
                return <CallIcon style={{ color: 'white', fontSize: 50 }}/>
            case 9:
                return <VideoLibraryIcon style={{ color: 'white', fontSize: 50 }}/>
            default:
                return <HelpIcon style={{ color: 'white', fontSize: 50 }}/>
        }
    }
    return (
        <div style={{ display: "none" }} >
            <div className="header-title-wrapper">
                <div className="header-title">
                    Gestão de agenda
                </div>
            </div>
            {
                loading ? (
                    <Loading center> Carregando conteúdos... </Loading>
                ) : (
                    <div className="row centralizar-row" style={{ marginLeft: -10, marginRight: -10 }}>
                        {
                            data.map((item, index) => {
                                return (
                                    <NavLink className="menu-card-content" key={index} to={`/gestao-de-conteudo/${transformText(item.name)}/`} exact={true}
                                        className="col-lg-3 col-md-3 col-sm-3 col-xs-6 col-6" style={{ padding: 10, border: 'none', textDecoration: 'none', cursor: 'pointer' }}>
                                        <div className="card card-effect">
                                            <div className="image-card" style={{ color: '#FFF', backgroundColor: primaryColor  }}>
                                                {getIconByType(item.idContentType)}
                                            </div>
                                            <div className="card-body">
                                                <h6 className="card-title" style={{ color: '#861414' }}>{capitalizeFirstLetter(item.name)}</h6>
                                                <span className="badge badge-pill badge-secondary badge-render">{getTypeName(item.idContentType)}</span>
                                            </div>
                                        </div>
                                    </NavLink>
                                )
                            })
                        }
                    </div>
                )
            }
        </div >
    );
}

export default GestaoAgenda
