import React, { useEffect } from "react";
import { HashRouter } from "react-router-dom";
import { AuthProvider } from './context/AuthContext'

import Routes from "./Routes";
import "./App.css";
import {  useClearCacheCtx } from 'react-clear-cache';

function App() {
    const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx();
    useEffect(()=> {
      if(!isLatestVersion){
        emptyCacheStorage();
      }
    }, [isLatestVersion, emptyCacheStorage])
  return (

    <HashRouter basename="/">
      <div className="App">
      <AuthProvider>
        <Routes />
      </AuthProvider>
      </div>
    </HashRouter>
  );
}

export default App;
