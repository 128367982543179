export const colors = {
    blueSmooth: '#F7F8FC',
    blueLight: '#DDE2FF',
    white: '#FFFFFF',
    black: '#252733',
    blackSmooth: "#363740",
    red: '#F12B2C',
    yellow: '#FEC400',
    green: '#29CC97',
    gray: '#9FA2B4',
    blue: '#3751FF',
}