import React, { useState, useContext } from 'react';
import './styles.scss'

import { LibraryContext } from '../index'

import { NexiDigitalAPI as api } from '../../../services/NexiDigitalAPI'

export default function ModalCreateFolder({ isopen, keyfolder }) {
  const { setOpenCreateFolderModal, getData, idSection } = useContext(LibraryContext)
  const [fodlerName, setFodlerName] = useState('')

  const createFolder = async () => {
    await api.post('/mediacenter/administrator/folder', {
      "key": keyfolder,
      "title": fodlerName
    })
    .then(() => {
      setOpenCreateFolderModal(false)
      setFodlerName('')
      getData(idSection)
    })
    .catch(() => {
      setOpenCreateFolderModal(false)
      setFodlerName('')
      getData(idSection)
    })
  }

  return (
    <div className={`modal__create-folder ${isopen ? 'open' : ''}`}>
      <div className="overlay" onClick={() => setOpenCreateFolderModal(false)}></div>
      <div className="dialog">
        <h3>Pasta</h3>
        <input type="text" placeholder="Insira o nome da sua pasta" value={fodlerName} onChange={e => setFodlerName(e.target.value)}/>
        <button onClick={createFolder}>Criar</button>
      </div>
    </div>
  )
}
