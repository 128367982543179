import React, { useState, useEffect, useRef } from 'react';
import {
        Col,
        Form,
    } from "react-bootstrap";
    import {
        FaFilter,
    } from "react-icons/fa";
import moment from "moment";
import "moment/locale/pt-br.js";
import { DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import { 
    getSectionsList, 
    getContentList,
    getGroupList,
} from "../../../../../services/NexiDigitalAPI";
import FiltroWrapper from '../../../Components/FiltroWrapper';
import styles from './styles.module.scss';
import ButtonWithLoading from '../../../../../components/ButtonWithLoading';

const Filtro = ({ actionFilter, loading }) => {
    const [values, setValues] = useState({
        dateStart: moment(new Date()).subtract(30, "days").format("YYYY-MM-DD"),
        dateFinish: moment(new Date()).format("YYYY-MM-DD"),
        idContentItem: 0,
        idSection: 0,
        idGroup: 0,
    });

    
    const [sectionList, setSectionList] = useState([]);
    const [contentList, setContentList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const isMountedRef = useRef(null);
    const appDetails = JSON.parse(localStorage.getItem('appDetails')) || null

    function handleDateStart(jsDate) {
        let date = moment(jsDate).format("YYYY-MM-DD");
        setValues({
            ...values,
            dateStart: date
        });
    }

    function handleDateFinish(jsDate) {
        let date = moment(jsDate).format("YYYY-MM-DD");
        setValues({
            ...values,
            dateFinish: date
        });
    }

    function handleSectionSelect(event) {
        setValues({
            ...values,
            idSection: parseInt(event.target.value)
        });
    }

    function handleGroupSelect(event) {
        setValues({
            ...values,
            idGroup: parseInt(event.target.value)
        });
      }

    function handleContentSelect(event) {
        setValues({
            ...values,
            idContentItem: parseInt(event.target.value)
        });
    }

    const submitFilter = () => {
        actionFilter(values)
    }

    async function updateListData(){
        const sectionListData = await getSectionsList( appDetails?.idAppType);
        setSectionList( sectionListData );
    }

    async function updateGroupListData(){
        const groupListData = await getGroupList();
        setGroupList( groupListData );
    }

    useEffect( () => {
        actionFilter(values);
        isMountedRef.current = true;
        updateListData();

        if(isMountedRef.current){
            updateGroupListData();
        }

        return () => (isMountedRef.current = false);
    }, []);
    
    async function updateContentList(){
        const contents = await getContentList(values.idSection);
        setContentList(contents);
        if(contents.length === 0){
            setValues({
                ...values,
                idContentItem: 0,
            });
        }
    }

    useEffect(() => {

        if (values.idSection !== -1 && values.idSection !== 0) {
            updateContentList();
        }else{
            setValues({
                ...values,
                idContentItem: 0,
                contentList: []
            });
            setContentList( [] );
        }
    }, [values.idSection]);

    return (
        <FiltroWrapper>
            <Form style={{ marginTop: 30, marginBottom: 30 }}>
                <Form.Row className="align-items-end">
                    <Col xs="12" md="4" lg="2" className={styles.formSpace}>
                        <Form.Label htmlFor="dateStart">Data de Início</Form.Label>
                        <DatePickerInput
                            locale="pt-br"
                            onChange={handleDateStart}
                            value={values.dateStart}
                        />
                    </Col>
                    <Col xs="12" md="4" lg="2" className={styles.formSpace}>
                        <Form.Label htmlFor="dateStart">Data de Término</Form.Label>
                        <DatePickerInput
                            locale="pt-br"
                            onChange={handleDateFinish}
                            value={values.dateFinish}
                        />
                    </Col>
                </Form.Row>
                <br />
                <Form.Row className="align-items-end">
                    <Col xs="12" md="4" lg="2" className={styles.formSpace}>
                        <Form.Group controlId="apps" className="mb-0">
                            <Form.Label>Seção</Form.Label>
                            <Form.Control
                                as="select"
                                onChange={handleSectionSelect}
                                defaultValue={0}
                            >
                                <option value={0}>Todas</option>
                                <option disabled="disabled">----</option>
                                {sectionList &&
                                    sectionList.map((section) => (
                                    <option
                                        value={section.idSection}
                                        key={section.idSection}
                                    >
                                        {section.name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs="12" md="4" lg="2" className={styles.formSpace}>
                        <Form.Group controlId="apps" className="mb-0">
                            <Form.Label>Conteúdos</Form.Label>
                            <Form.Control
                                as="select"
                                disabled={contentList.length === 0}
                                onChange={handleContentSelect}
                            >
                                <option value={0}>Selecione</option>
                                {contentList &&
                                    contentList.map((content) => (
                                    <option
                                        value={content.idContentItem}
                                        key={content.idContentItem}
                                    >
                                        {content.text}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs="12" md="4" lg="2" className={styles.formSpace}>
                        <Form.Group controlId="apps" className="mb-0">
                            <Form.Label>Grupos</Form.Label>
                            <Form.Control
                                as="select"
                                onChange={handleGroupSelect}
                                defaultValue={0}
                            >
                                <option value={0}>Todos</option>
                                <option disabled="disabled">----</option>
                                {groupList &&
                                    groupList.map((group) => (
                                    <option
                                        value={group.idGroups}
                                        key={group.idGroups}
                                    >
                                        {group.title}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs="12" md="4" lg="2" className={styles.formSpace}>
                        <Form.Group className="mb-0">
                            <ButtonWithLoading 
                                onClickHandler={submitFilter}
                                loading={loading}
                                text={( <>Aplicar filtro <FaFilter style={{ marginLeft: 5 }} /> </> )}
                            />
                        </Form.Group>
                    </Col>
                </Form.Row>
            </Form>
        </FiltroWrapper>
    );
}

export default Filtro;