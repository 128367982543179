import React, { useState, useEffect, useContext } from "react";
import { Redirect, NavLink } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import {
    updateAppDetails,
    uploadImageApi,
    getSectionTypeList,
    getSectionsStructureList,
} from "../../services/NexiDigitalAPI";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { colors } from "../../utils/Colors";
import Loading from "../../components/Loading";
import "react-sortable-tree/style.css";
import SortableTree from "react-sortable-tree";

function SectionList({ newSection }) {
    const { loginData } = useContext(AuthContext);
    const { primaryColor, secondaryColor, thirdColor, fourthColor } = loginData;
    const [sectionList, setSectionList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const appDetails = JSON.parse(localStorage.getItem("appDetails")) || null;

    function moveSection(treeData) { 
        setSectionList(treeData)

    }

    function transformArray(sectionListArray) {
        if (!Boolean(sectionListArray)) {
            return [];
        }
        sectionListArray.forEach((item) => {
            item.title = item.text;
            item.expanded = true
            if (item.children?.length > 0) {
                item.children = transformArray(item.children);
            }
        });

        return sectionListArray;
    }

    function getSectionsList() {
        getSectionsStructureList(appDetails?.idAppType)
            .then((res) => {
                let result = res?.result?.appStructure;
                setSectionList(transformArray(result));
            })
            .catch((err) => {
                console.log("Erro na chamada de API.", err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    useEffect(() => {
        getSectionsList();
    }, []);

    useEffect(() => {
        if (newSection && Object.keys(newSection).length > 0) {
            setSectionList([
                ...sectionList,
                {
                    format: newSection.format,
                    idApp: appDetails.idAppType,
                    idSection: newSection.idSection,
                    idSectionType: newSection.idSectionType,
                    name: newSection.name,
                    ordering: newSection.ordering,
                    parent: 1,
                    sectionTypeName: newSection.sectionTypeName,
                },
            ]);
        }
    }, [newSection]);

    return (
        <div className="content-manager-container">
            <div className="section-manager-content-container">
                <div className="section-manager-content-title">Lista de seções</div>
                <div className="section-manager-section-list">
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <div style={{ height: (window?.innerHeight - 300) || 700 }}>
                            {sectionList?.length > 0 ? (
                                <SortableTree
                                    treeData={sectionList}
                                    onChange={(treeData) => setSectionList(treeData)}
                                    onMoveNode={(node) => console.log('node', node)}
                                />
                            ) : (
                                <div className="section-manager-section-no-data">
                                    Nenhum dado disponível
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default SectionList;
