// Basic
import React from "react";
import LojaWrapper from '../Components/LojaWrapper';
import Acordeon from '../../../components/Acordeon';
import VendasDiarias from './SubReports/VendasDiarias'
import VendasProdutos from './SubReports/VendasProdutos'


const RelatorioVendas = () => {
  return (
    <LojaWrapper>    
      <Acordeon name="Vendas Diárias" index="1">
        <VendasDiarias />
      </Acordeon>
      <Acordeon name="Vendas de produto" index="2">
        <VendasProdutos />
      </Acordeon>
    </LojaWrapper>
  )
}

export default RelatorioVendas