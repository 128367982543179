// Basic
import React from "react";
import BestInRanking from './SubReports/BestInRanking';
import WorstInRanking from './SubReports/WorstInRanking';
import ReportWrapper from '../Components/ReportWrapper';
import ReportListTitle from '../Components/ReportListTitle';


const ReportRanking = ({ rel }) => {

  const reportList = [
    (index, expanded) => <BestInRanking index={index} expanded={expanded} key={index}/>,
    (index, expanded) => <WorstInRanking index={index} expanded={expanded} key={index}/>,
  ]

  return (
    <ReportWrapper>
      <ReportListTitle>
        Lista de Relatórios
      </ReportListTitle>
      {
        reportList.map((report, index) => {
          const expand = (index.toString() === rel);
          return report(index, expand)
        }
      )}
    </ReportWrapper>
  )
}

export default ReportRanking