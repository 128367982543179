import React from 'react'

export default function Loading ({children, containerStyle, loadingStyle, center}) {
  const centerDesign = {
     position: 'absolute', 
     top: 'calc(50% - 80px)', 
     height: 'auto'
  }

  const getCenter = () => {
    if(center){
      return centerDesign
    }
    return {}
  }
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'column',
        height: 500,
        ...getCenter(),
        ...containerStyle
      }}
    >
      <div className="loader loader-big" style={{ marginBottom: 15, ...loadingStyle }} />
      { children }
    </div>
  )
}
