import React, { useState, useEffect, useContext, useRef } from "react";
import { ListGroup, Modal } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { AuthContext } from "../../../context/AuthContext";
import {
  getListCampaignsDetail,
  getTags,
  getCampaignsEvents,
} from "../../../services/NexiDigitalAPI";
import { useParams } from "react-router-dom";
import _ from "underscore";

import moment from "moment";
import "moment/locale/pt-br";
const ReportGeneral = () => {
  const { loginData } = useContext(AuthContext);
  const { id } = useParams();

  const primaryColor = loginData?.primaryColor;
  const secondaryColor = loginData?.secondaryColor;
  const [show, setShow] = useState(false);
  const [selectionTags, setSelectionTags] = useState([]);
  const isMountedRef = useRef(null);
  const [campanha, setNameCampanha] = useState();
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const [loading, setLoading] = useState();
  const [listaOrdenada, setListaOrdenada] = useState([]);
  const [events, setEvents] = useState([]);
  const [eventSelected, setEventSelected] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (id && listaOrdenada.length && events.length) {
      setLoading(true);
      getListCampaignsDetail(id)
        .then(async (res) => {
          setNameCampanha(res.campaign[0].name);
          setEndDate(moment(res.campaign[0].endDate).format("DD/MM/YYYY"));
          setStartDate(moment(res.campaign[0].startDate).format("DD/MM/YYYY"));
          let _events = [];
          res.events.forEach((elem) => {
            let obj = {};
            obj.id = elem.idEvent;
            obj.tags = elem.tags.length
              ? elem.tags.map((elem) => {
                  let _tag = listaOrdenada.find((tag) => tag.id === elem);
                  if (_tag) {
                    return _tag.name;
                  }
                })
              : [];
            obj.points = elem.points;
            obj.name = events.find(
              (event) => event.idgamification_event === elem.idEvent
            ).event;
            obj.conclude = elem.conclude;
            _events = [..._events, obj];
          });
          setEventSelected(_events);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(true);
          setError(true);
          setEvents([]);
        });
    } else {
      // setNameCampanha(_data[0] || "");
    }
  }, [id, listaOrdenada, events]);
  useEffect(() => {
    isMountedRef.current = true;
    setLoading(true);

    getTags()
      .then((res) => {
        if (isMountedRef.current) {
          const tagSearch = res.data.tagsearch;
          const tagsSorted = _.sortBy(tagSearch, "tag");
          let nova = [];

          for (let i = 0; i < tagsSorted.length; i++) {
            nova.push({ id: tagsSorted[i].idTag, name: tagsSorted[i].tag });
          }
          setListaOrdenada(nova);
        }
      })
      .catch((err) => {
        setListaOrdenada([]);
      })
      .finally(() => {
        setLoading(false);
      });

    return () => (isMountedRef.current = false);
  }, []);

  useEffect(() => {
    setLoading(true);

    isMountedRef.current = true;
    getCampaignsEvents()
      .then((res) => {
        if (isMountedRef.current) {
          setEvents(res);
        }
      })
      .catch((err) => {
        setError(true);
        setEvents([]);
      });

    return () => (isMountedRef.current = false);
  }, []);

  const Tags = (_tags) => {
    if (_tags.length > 3) {
      return (
        <>
          <span>{`${_tags[0]}, ${_tags[1]}, ${_tags[2]}...`}</span>
          <span
            onClick={() => {
              setSelectionTags(_tags);
              setShow(true);
            }}
            style={{ color: "blue", cursor: "pointer" }}
          >
            Ver tudo
          </span>
        </>
      );
    } else {
      return <span>{`${_tags.sort().join(", ")}`}</span>;
    }
  };

  return (
    <div className="Reports">
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
      >
        <Modal.Header
          style={{ backgroundColor: primaryColor, color: "white" }}
          closeButton
        >
          <Modal.Title>Lista TAGs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup variant="flush">
            {selectionTags.map((item, index) => (
              <ListGroup.Item key={index}>{item}</ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
      </Modal>
      <div
        className="report-filter-container"
        style={{
          border: "1px solid lightgray",
          overflow: "inherit",
        }}
      >
        {loading ? (
        
          <div className="div-loading">
            <>
              {!error ? (
                <div className="loader loader-big" />
              ) : (
                <div>Tivemos uma falha ao carregar a tabela.</div>
              )}
            </>
          </div>
        ) : (
          <>
            <div style={{ marginTop: 30, marginBottom: 40 }}>
              <div className="text-left" style={{ color: "#403f3f" }}>
                {campanha}
              </div>
              <div
                className="text-left-subtitle"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                <div style={{ color: "#403f3f" }}>
                  <span
                    style={{ marginRight: "10px" }}
                  >{` Início: ${startDate}`}</span>
                </div>
                <div style={{ color: "#403f3f" }}>
                  <span> {` Término: ${endDate}`}</span>
                </div>
              </div>
            </div>
            <Table style={{ marginBottom: 20 }}>
              <Thead>
                <Tr>
                  <Th>Evento</Th>
                  <Th>Descrição</Th>
                  <Th>Tags</Th>
                  <Th>Concluído</Th>
                  <Th>Pontuação</Th>
                </Tr>
              </Thead>
              <Tbody>
                <>
                  {eventSelected.map((item, index) => (
                    <Tr
                      key={item.id}
                      style={{
                        backgroundColor:
                          index % 2 === 0
                            ? `${secondaryColor.trim()}22`
                            : `${secondaryColor.trim()}14`,
                      }}
                    >
                      <Td >{item.id}</Td>
                      <Td >{item.name}</Td>
                      <Td >{item.tags.length ? Tags(item.tags) : "Sem tags"}</Td>
                      <Td >{item.conclude === 0 ? 'NÃO' : 'SIM'}</Td>
                      <Td >{item.points}</Td>
                    </Tr>
                  ))}
                </>
              </Tbody>
            </Table>
          </>
        )}
      </div>
    </div>
  );
};

export default ReportGeneral;
