import React, {useContext} from 'react';
import { AuthContext } from "../../context/AuthContext";

const CmsTitulo = ({titulo, setTitulo, description, setDescription}) => {

    const { loginData } = useContext(AuthContext);
    const fourthColor = loginData?.fourthColor;
    const secondaryColor = loginData?.secondaryColor;

    return (
        <div className="form-group">
            <div className="card">
                <div
                    style={{ backgroundColor: fourthColor || secondaryColor }}
                    className="card-header template-card-header"
                >
                    Título
                </div>
                <div className="card-body template-card-body">
                    <div className="input-group mb-4">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1">
                                Título
                            </span>
                        </div>
                        <input
                            type="text"
                            className="form-control"
                            id="titulo"
                            aria-describedby="titulo"
                            value={titulo}
                            onChange={(e) => setTitulo(e.target.value)}
                        />
                    </div>
                    <div className="input-group mb-4">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1">
                                Descrição
                            </span>
                        </div>
                        <input
                            type="text"
                            className="form-control"
                            id="description"
                            aria-describedby="description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CmsTitulo;