import React from 'react'
import { Button } from "react-bootstrap";

const ButtonWithLoading = ({ onClickHandler, loading, text, style }) => {
    const buttonStyle = {
        marginTop: 10, 
        backgroundColor: localStorage.getItem("secondaryColor"),
        ...style
    };

    return (
        <Button onClick={onClickHandler} style={buttonStyle} >
            {loading ? (
                <>
                Carregando...
                </>
            ) : (
                <>
                {text}
                </>
            )}
        </Button>
    )
}

export default ButtonWithLoading;