import React, { useRef, useState, useEffect } from "react";
import { Chart } from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "../../styles.scss";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import MenuChart from './Components/MenuChart'
import styles from './styles.module.scss'
const ChartsDays = ({ data, Period, title }) => {
  //data for excel generation
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  Chart.register(ChartDataLabels);
  function handleWindowSizeChange() {
    setIsMobile(window.innerWidth <= 600);
  }

  const [labels,setLabels] = useState([]);
  const [dados,setDados] = useState([]);

  useEffect(() => {
    if(data){
      let labelsList = [];
      let dadosList = [];
      data.map((value,index)=>{
        dadosList.push(value.totalTime);
        labelsList.push(moment(value.Day).format("DD/MM"));
        return( [moment(value.Day).format("DD/MM") , value.totalTime ] );
      })
      setDados(dadosList);
      setLabels(labelsList);
    }
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const refChart = useRef(null);
  const updateChartRef = ({
    datalabels = true,
    title = true,
    subtitle = true,
    resize = false,
  }) => {
    
    refChart.current.options.plugins.datalabels.display = datalabels;
    refChart.current.options.plugins.title.display = title;
    refChart.current.options.plugins.subtitle.display = subtitle;
    if (resize) {
      refChart.current.canvas.parentNode.style.height = "300px";
      refChart.current.canvas.parentNode.style.width = isMobile
        ? "800px"
        : "calc(100% - 220px)";
    } else {
      refChart.current.canvas.parentNode.style.height = "300px";
      refChart.current.canvas.parentNode.style.width = "calc(100% - 220px)";
    }
    refChart.current.update();
  };

  const downloadImg = async () => {
    document.getElementById("loader").style.display = "flex";
    updateChartRef({
      datalabels: true,
      title: true,
      subtitle: true,
      resize: true,
    });

    setTimeout(() => {
      let input = refChart.current;
      const img = input.canvas.toDataURL("image/png", 1.0);

      var link = document.createElement("a");

      link.download = `${Period.replace("Período: ", "").replace(" ", "")}.png`;
      link.href = img;
      link.click();
      updateChartRef({
        datalabels: false,
        title: false,
        subtitle: false,
        resize: false,
      });
      document.getElementById("loader").style.display = "none";
    }, 1000);
  };
  const downloadPdf = async (e) => {
    updateChartRef({ title: false, subtitle: false, resize: true });
    document.getElementById("loader").style.display = "flex";
    //delay para mudança no chartjs
    setTimeout(() => {
      let input = refChart.current.canvas;
      const img = input.toDataURL("image/png", 1.0);
      const pdf = new jsPDF("portrait", "pt");
      const imgProps = pdf.getImageProperties(img);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(img, "JPEG", 0, 300, pdfWidth, pdfHeight);
      pdf.autoTable({
        styles: {
          fillColor: "#dadaeb",
          halign: "center",
          cellPadding: 0.3,
          fontSize: 12,
          textColor: "black",
        },
        margin: { top: 100 },
        head: [["Dia", "Visualizações"]],
        body: 
          data
        ,
      });
      pdf.setFontSize(20);
      pdf.setFont("times");
      pdf.text(30, 30, "Utilização por dia da semana");
      pdf.setFontSize(12);
      pdf.setFont("times");
      pdf.text(40, 98, `${Period}`);
      pdf.setFontSize(10);
      pdf.text("Relatório utilização por semana", 250, pdfHeight + 300);
      pdf.save(`${Period.replace("Período: ", "").replace(" ", "")}.pdf`);

      updateChartRef({
        datalabels: false,
        title: false,
        subtitle: false,
        resize: false,
      });

      document.getElementById("loader").style.display = "none";
    }, 1000);
  };

  return (
    <div className={styles.chartWrapper}>
      <div id="loader" className="loadingCharts">
        <div className="loader loader-big" />
        <span>Realizando download...</span>
      </div>
      <div className={styles.positionIcon}>
        { title }
      </div>
      <div style={{ width: "100%", height: "300px" }}>
        <Bar
          ref={refChart}
          width={350}
          data={{
            labels: labels,
            datasets: [
              {
                data: dados,
                backgroundColor: "#6a51a3",                
                borderColor: ["#756bb1"],
                borderWidth: 1,
                pointBackgroundColor: "#fff",
                datalabels: {
                  color: ["#fff"],
                },
              },
            ],
          }}
          options={{
            layout: { padding: 20 },
            responsive: true,
            maintainAspectRatio: false,
            animation: true,
            scales: {
              y: {
                title: {
                  display: true,
                  text: "Visualizações",
                  color: "#808080",
                  font: {
                    size: isMobile ? 13 : 20,
                  },
                  padding: {
                    right: 10,
                  },
                },
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              datalabels: {
                display: false,
                color: "white",
                font: {
                  size: 15,
                },
              },
              title: {
                display: false,
                text: ["Utilização por dia da semana"],
                font: {
                  size: 20,
                },
                position: "top",
                align: "start",
              },
              subtitle: {
                display: false,
                position: "top",
                align: "start",
                padding: { bottom: 20 },
                font: {
                  size: 15,
                },
                text: `${Period}`,
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default ChartsDays;
