import React from 'react';
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import styles from './styles.module.scss';
import { colors } from '../../../../../utils/Colors'

const Table = ({ dados, loading }) => {

    const reportSessionsColumns = [
        { name: "ID", selector: "idSession", sortable: true, width: "10%" },
        { name: "Nome", selector: "name", sortable: true, width: "20%" },
        { name: "E-mail", selector: "email", sortable: true, width: "25%" },
        {
          name: "Última visita",
          selector: "lastVisit",
          sortable: true,
          center: true,
          width: "15%",
        },
        {
          name: "Visitas",
          selector: "qtyVisits",
          sortable: true,
          center: true,
          width: "10%",
        },
        {
          name: "Visualizações",
          selector: "objWatched",
          sortable: true,
          center: true,
          width: "10%",
        },
        {
          name: "Tempo decorrido",
          selector: "realTimeElapsed",
          sortable: true,
          center: true,
          width: "10%",
        },
    ];

    const customTableStyles = {
        rows: {
          style: {
            minHeight: '60px', // override the row height
          }
        },
        headCells: {
          style: {
            paddingLeft: '30px', // override the cell padding for head cells
          },
        },
        cells: {
          style: {
            paddingLeft: '30px', // override the cell padding for data cells
          },
        },
        header: {
          style: {
            fontSize: '22px',
            minHeight: '56px',
            paddingLeft: '28px',
            paddingRight: '8px',
            paddingTop: '30px',
            paddingBottom: '15px',
          },
        },
    };

    const loadingComponent = () => {
      return (
        <div className="tela-login-loading">
          <div className="loader-container" style={{ margin: '300px 0' }}>
            <div
              style={{
              borderTopColor: colors.gray,
              position: 'initial',
              margin: 'initial'
              }}
              className="loader loader-center loader-big">
            </div>
            <span style={{
              marginTop: 10,
              color: colors.gray,
              fontSize: 20
            }}>
              Carregando dados...
            </span>
          </div>
        </div>
      )
    }

    const tableData = {
    columns: reportSessionsColumns,
    data: dados
    }


    return (
        <div className="report-table-container">
            <DataTableExtensions filter={false} print={false} {...tableData} exportHeaders={true}>
                <DataTable
                    columns={reportSessionsColumns}
                    data={dados}
                    pagination
                    className={styles.dataTableFormat}
                    customStyles={customTableStyles}
                    progressPending={ loading }
                    progressComponent={loadingComponent()}
                    noHeader={true}
                    noDataComponent="Não foi encontrado nenhum dado"
                />
            </DataTableExtensions>
        </div>
    )
}

export default Table;