import React from "react";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "../styles.scss";
import ReportGeneral from "./ReportGeneral";
import ReportUser from "./ReportUser";
const ReportsGamification = () => {
  return (
    <div className="Reports">
      <div className="header-title-wrapper">
        <div className="header-title">Relatórios</div>
      </div>

      <Tabs defaultIndex={0}>
        <TabList>
          <Tab key={1}>Resumo</Tab>
          <Tab key={2}>Conteúdo</Tab>
        </TabList>
        <TabPanel>
          <ReportGeneral />
        </TabPanel>
        <TabPanel>
          <ReportUser />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default ReportsGamification;
