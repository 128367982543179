// Basic
import React, { useState, useEffect } from "react";
import LojaWrapper from '../Components/LojaWrapper';
import { listPurchase, cancelpurchase } from '../../../services/NexiDigitalAPI';
import Table from './components/Table'
import Modal from './components/Modal'
import Filtro from './components/Filter'

const EstornoVendas = () => {

  const [purchase, setPurchase] = useState([])
  const [info, setInfo] = useState({})
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const initialValue = {
    idPurchase: 0,
    status: 1
  }

  const updatePurchase = async (value) => {
    setLoading(true)
    const sendValue = {
      ...value,
      idPurchase: (value.idPurchase !== '') ? value.idPurchase : 0
    }
    const purchase = await listPurchase(sendValue);
    setPurchase( purchase )
    setLoading(false)
  } 

  const submitOk = async ( id ) => {
    const cancel = await cancelpurchase( id )
    if(cancel){
      updatePurchase(initialValue)
    }
    hideModal()
  }

  const showModal = (info) => {
    setInfo(info);
    setShow(true);
  }

  const hideModal = () => setShow(false);

  useEffect( () => {
    updatePurchase(initialValue)
  }, []);
  

  return (
    <LojaWrapper> 
      <Filtro actionFilter={updatePurchase} loading={loading} /> 
      <Table List={purchase} returnSale={showModal} loading={loading} />
      <Modal show={show} hideModal={hideModal} info={info} submitOk={submitOk} />
    </LojaWrapper>
  )
}



export default EstornoVendas