import React, { useContext, useState, useEffect } from "react";
import "./Cockpit.css";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { contentGroups, getMenuData } from "../services/NexiDigitalAPI";
import { transformText, getMenuRoutes, getMenuIconTypes } from "../utils/Utils";
import TermsModal from "../components/TermsModal";

export default function Cockpit() {
  const bucketUrl = window.location.host;
  const urlToken = window.location.search.slice(7);
  const { loginData } = useContext(AuthContext);
  const primaryColor = loginData?.primaryColor;
  const [showModal, setShowModal] = useState(false);
  const appDetails = JSON.parse(localStorage.getItem("appDetails")) || null;
  function showTerms() {
    let loginInfo = getMenuData();
    if (!loginInfo) {
      return;
    }
    if (
      loginInfo?.userLicenceTermsAccepted !== loginInfo?.agreementVersion ||
      (appDetails?.showOfferTerm === 1 && loginInfo?.offerTermsAccepted === 0)
    ) {
      setShowModal(true);
    }
  }

  useEffect(() => {
    if (urlToken.length > 50) {
      window.location.replace(`//${bucketUrl}/`);
    }
    showTerms();
    contentGroups()
      .then((res) => {
        let array = res.data.contentGroups;
        localStorage.setItem("contentGroups", JSON.stringify(array));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function getMenuIcon(idSectionType) {
    const IconComponent = getMenuIconTypes(idSectionType);

    return <IconComponent style={{ color: "white", fontSize: 50 }} />;
  }

  return (
    <div className="Cockpit">
      <div className="header-title-wrapper">
        <div className="header-title"> Início </div>
      </div>
      {showModal && (
        <TermsModal
          showModal={showModal}
          setShowModal={(e) => setShowModal(e)}
        />
      )}

      <div className="cockpit-cards-container">
        {getMenuRoutes()?.map((item, index) => {
          return (
            <Link
              to={`/${transformText(item.text)}/${item.sectionTo}`}
              className="cockpit-card"
              key={index}
            >
              <div
                className="cockpit-card-header"
                style={{ backgroundColor: primaryColor }}
              >
                {getMenuIcon(item.idSectionType)}
              </div>
              <div className="cockpit-card-content">{item.text}</div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}
