import React, {useState, useEffect, useContext} from 'react';
import { AuthContext } from "../../context/AuthContext";
import { getTags } from "../../services/NexiDigitalAPI";
import Tags from "../../components/Tags";
import _ from "underscore";


const CmsTags = ({setTags, contentTags}) => {

    const { loginData } = useContext(AuthContext);
    const fourthColor = loginData?.fourthColor;
    const secondaryColor = loginData?.secondaryColor;
    
    const [lista, setLista] = useState([]);

    useEffect(() => {
        getTags()
            .then((res) => {
                const tagSearch = res.data.tagsearch;
                const tagsSorted = _.sortBy(tagSearch, "tag");
                let nova = [];

                for (let i = 0; i < tagsSorted.length; i++) {
                    nova.push({ id: tagsSorted[i].idTag, name: tagsSorted[i].tag });
                }

                setLista(nova);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return(
        <div className="form-group">
            <div className="card">
                <div
                    style={{ backgroundColor: fourthColor || secondaryColor }}
                    className="card-header template-card-header"
                >
                    Tags
                </div>
                <div className="card-body template-card-body">
                    <div className="row">
                        <div className="col-md-12">
                            <Tags
                                handleNewTags={(newTags) => setTags(newTags)}
                                lista={lista}
                                contentTags={contentTags}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CmsTags