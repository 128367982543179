import React from 'react'
import styles from './styles.module.scss'


const ReportListTitle = ({ children }) => {
    return (
        <div className={styles.reportListTitle} >
            { children }
        </div>
    )
}

export default ReportListTitle;