import React, { useState } from "react";
import Filtro from './Filtro';
import Table from './Table';
import ReportAcordeon from '../../../Components/ReportAcordeon'
import { reportUserUpload } from "../../../../../services/NexiDigitalAPI";


const UploadsRealizados = ({ index, expanded }) => {
    const name = "Uploads realizados pelos usuários"
    const description = "";

    const [loading, setLoading] = useState(false);
    const [dados, setDados] = useState([]);

    const actionFilter = async (values) => {
        setLoading(true);
        const data = await reportUserUpload(values);
        setDados(data);
        setLoading(false);
    }

    const filter = (
        <Filtro 
            actionFilter={actionFilter} 
            loading={loading} 
        />
    );

    const result = (
        <Table 
            dados={dados} 
            loading={loading}
        />
    );
    
    return (
        <ReportAcordeon
            name={name}
            description={description}
            filter={filter}
            result={result}
            index={ index }
            expanded={expanded}
        />
        
    )
}

export default UploadsRealizados;