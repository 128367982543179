import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import {
    getSectionsStructureList,
} from "../../services/NexiDigitalAPI";
import { colors } from "../../utils/Colors";
import Loading from "../../components/Loading";
import ListIcon from '@material-ui/icons/List';
import MenuOpen from '@material-ui/icons/MenuOpen';
import ArrowBack from '@material-ui/icons/ArrowBack';
import SectionList from "./SectionList";
import SectionDesignList from "./SectionDesignList";
import MenuDesignList from "./MenuDesignList";

function DesignManager() {
    const { loginData } = useContext(AuthContext);
    const { primaryColor, secondaryColor, thirdColor, fourthColor } = loginData;
    const [activeItemIndex, setActiveItemIndex] = useState(null)
    const appDetails = JSON.parse(localStorage.getItem("appDetails")) || null;

    const getCurrentItemName = () => {
      switch(activeItemIndex) {
        case 0: return "/ Menu"
        case 1: return "/ Seção"
        default: return ""
      }
    }


    return (
        <div className="content-manager-container">
            <div className="section-manager-content-container">
                <div className="section-manager-content-title">Tipos de listagem {getCurrentItemName()}</div>
                <div className="section-manager-type-list-container">
                  {
                    activeItemIndex === null && (
                      <div>
                        <div 
                          style={{ backgroundColor: colors.blackSmooth }} 
                          className="section-manager-type-list-item"
                          onClick={() => setActiveItemIndex(0)}
                        >
                          <MenuOpen style={{ fontSize: 60 }} className="section-manager-type-list-icon" /> 
                          <div>
                            <span className="section-manager-type-list-title">Menu</span>
                            <div className="section-manager-type-list-text">Este é o menu principal onde lista os itens que redireciona à outras seções.</div>
                          </div>
                        </div>
                        <div 
                          style={{ backgroundColor: colors.blackSmooth }} 
                          className="section-manager-type-list-item"
                          onClick={() => setActiveItemIndex(1)}
                        >
                          <ListIcon style={{ fontSize: 60 }} className="section-manager-type-list-icon" /> 
                          <div>
                            <span className="section-manager-type-list-title">Seção</span>
                            <div className="section-manager-type-list-text">Item do menu que redireciona a outra seção ou conteúdo.</div>
                          </div>
                        </div>
                      </div>
                    )
                  }
                  {
                    activeItemIndex === 0 && (
                      <div>
                        <div 
                          onClick={() => setActiveItemIndex(null)}
                          className="design-manager-back-container"
                        >
                          <ArrowBack style={{ marginRight: 5 }}/>
                          <span >Voltar</span>
                        </div>
                        <MenuDesignList />
                      </div>
                    )
                  }
                  {
                    activeItemIndex === 1 && (
                      <div>
                        <div 
                          onClick={() => setActiveItemIndex(null)}
                          className="design-manager-back-container"
                        >
                          <ArrowBack style={{ marginRight: 5 }}/>
                          <span >Voltar</span>
                        </div>
                        <SectionDesignList />
                      </div>
                    )
                  }
                </div>
            </div>
        </div>
    );
}

export default DesignManager;
