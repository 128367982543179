import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';
import './UploadList.scss'

import Loading from "../../../components/Loading";
import { AuthContext } from "../../../context/AuthContext";
import { NexiDigitalAPI as api } from '../../../services/NexiDigitalAPI'


const UploadList = () => {
  const [filteredList, setFilteredList] = useState([])
  const [uploadList, setUploadList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)

  const { loginData } = useContext(AuthContext);
  const secondaryColor = loginData.secondaryColor;


  function checkFileIsImage(file) {
    return file ? file.includes('image') : false
  }

  useEffect(() => {
    async function getUploadList() {
      setIsLoading(true)

      await api.get(`/userupload/listall`)
        .then(({ data: { data } }) => {
          setIsLoading(false)
          setUploadList(data)
          setFilteredList(data)
        })
        .catch(() => { setIsLoading(false); setError(error) })
    }

    getUploadList()
  }, [])

  const filterStatus = (event) => {
    const status = event.target.value

    if(status === 'Selecione') {
      setFilteredList(uploadList)
    } else {
      const filteredList = uploadList.filter(({ status: uploadStatus }) => uploadStatus.toUpperCase() === status.toUpperCase())
      setFilteredList(filteredList)
    }
  }

  return (
    <div className="upload-list">
      {
        isLoading ?
          <Loading center> Carregando conteúdos... </Loading>
          :
          error ?
            <div style={{ marginTop: 280 }}>Houve algum erro durante o carregamento das informações</div>
            :
            <>
              <div className="upload-list__filters">
                <label>Status</label>
                <select onChange={filterStatus}>
                  <option>Selecione</option>
                  <option value="P">Não Publicado</option>
                  <option value="A">Publicado</option>
                  <option value="C">Cancelado</option>
                </select>
              </div>

              <div className="upload-list__header" style={{ backgroundColor: secondaryColor }}>
                <span>Arquivo</span>
                <span>Autor</span>
                <span>Título</span>
                <span>Descrição</span>
                <span>Seção Origem</span>
                <span>Seção Destino</span>
                <span>Status</span>
                <span>Ação</span>
              </div>
              <ul className="upload-list__content">
                {
                  filteredList.map(({ idUserUpload, name, title, description, mimetype, extension, url, status, sectionSource, sectionTarget }) => (
                    <li className="upload-list__item" key={idUserUpload}>
                      <div className="upload-list__item--image">
                        {
                          checkFileIsImage(mimetype) ?
                            <img src={url} alt="" />
                            :
                            <i className={extension || 'generic'} />
                        }
                      </div>

                      <div className="upload-list__item--author">
                        <h3>{name}</h3>
                      </div>

                      <div className="upload-list__item--title">
                        <h3>{title}</h3>
                      </div>

                      <div className="upload-list__item--description">
                        <p>{description}</p>
                      </div>

                      <div className="upload-list__item--section">
                        <p>{sectionSource}</p>
                      </div>

                      <div className="upload-list__item--section">
                        <p>{sectionTarget ? sectionTarget : '-'}</p>
                      </div>

                      <div className="upload-list__item--status">
                        {status === 'P' ?
                          <span className="badge badge-pill badge-warning badge-render">Não publicado</span>
                          :
                          status === 'C' ?
                            <span className="badge badge-pill badge-danger badge-render">Cancelado</span>
                            :
                            <span className="badge badge-pill badge-success badge-render">Publicado</span>
                        }
                      </div>

                      <Link
                        className="upload-list__item--edit"
                        to={`/upload/${idUserUpload}`}
                      >
                        <span style={{ backgroundColor: secondaryColor }}>Editar</span>
                      </Link>
                    </li>
                  ))
                }
              </ul>
            </>
      }
    </div>
  )
}

export default withRouter(UploadList);
